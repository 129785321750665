// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.supportMainTopSection {
    background-color: #25335b;
    padding: 25px 15px !important;
    color: #fff !important;
}
.text-center {
    text-align: center!important;
}`, "",{"version":3,"sources":["webpack://./src/SidebarComponent/FAQs.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,sBAAsB;AAC1B;AACA;IACI,4BAA4B;AAChC","sourcesContent":[".supportMainTopSection {\r\n    background-color: #25335b;\r\n    padding: 25px 15px !important;\r\n    color: #fff !important;\r\n}\r\n.text-center {\r\n    text-align: center!important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react'
import { Col, Container, Row,Accordion } from 'react-bootstrap'
import './FAQs.css';

export default class FAQs extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }
  render() {
    return (
      <div>
        <Container>
           <Row>
             <Col lg="12" md="12" className='text-center supportMainTopSection'>
               <h3>How Can We Help You?</h3>
               <p>you can also find the topics below to find what you are looking for</p>
             </Col>
           </Row>
        </Container>
        <Container>
          <Row>
            <br/>
          </Row>
          <Row>
              <Accordion defaultActiveKey={['0']} flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>1. What are benefite using Apana Policy?</Accordion.Header>
                      <Accordion.Body>
                      1 Apna Policy Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </Accordion.Body>
                    </Accordion.Item>
                    <br/>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>2. Whar is health insurance?</Accordion.Header>
                      <Accordion.Body>
                      2 health insurance Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </Accordion.Body>
                    </Accordion.Item>
                    <br/>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>3.Why should I buy health insurance?</Accordion.Header>
                      <Accordion.Body>
                      3 health insurance Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </Accordion.Body>
                    </Accordion.Item>
                    <br/>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>4.How will health insurance pay for my emergency medical expenses?</Accordion.Header>
                      <Accordion.Body>
                      4 emergency medical expenses health insurance Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </Accordion.Body>
                    </Accordion.Item>
                    <br/>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>5.Can I pay my health insurance premium in installments?</Accordion.Header>
                      <Accordion.Body>
                      5 installments emergency medical expenses health insurance Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </Accordion.Body>
                    </Accordion.Item>
                    <br/>
                    <Accordion.Item eventKey="5">
                      <Accordion.Header>6.Will I be eligible for tax benefits if I buy health insurance?</Accordion.Header>
                      <Accordion.Body>
                      6 insurance emergency medical expenses health insurance Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
          </Row>
        </Container>
      </div>
    )
  }
}
import React, { Component } from 'react'
import { Container ,Row,Col} from 'react-bootstrap'

export default class KnowledgeCenter extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }
  render() {
    return (
      <div>
        <Container className='bgalphacolor'>
            <Row>
                <Col lg="12" md="12">
                       <h3
                        className="text-center logoskybluecolor">
                          KnowledgeCenter</h3>
                </Col>
                </Row>
                </Container>
      
      </div>
    )
  }
}

import React, { Component } from 'react'
import { Container,Row,Col, Image } from 'react-bootstrap'
import Banner2 from '../Images/Banner2.jpg'


export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }
  render() {
    return (
        <div> 
         <Container className='bgalphacolor'>
        <Row><br/></Row>
        <Row>
          <Col lg="5" md="5">
            <Image src={Banner2} className='img-fluid' alt='Responsive image'/>
          </Col>
          <Col lg="7" md="7">
          <h3 className="logoskybluecolor">Services</h3>
            <p>We have the distinguished record of providing impeccable service to all our esteemed clientele.</p>
            <p><b>Risk Management - </b> We do an in- depth study on the risk involved in the business of Clients at
             different stages and accordingly provide apt solutions to minimise the losses.</p>
            <p><b>Sum Insured - </b> Our experience helps us to estimate the exact cost of the machinery and 
            assets at which they should be insured.</p>
            <p><b>Claims Management Services - </b> We have a dedicated team of experts who look into the
             claim management with expertise, so that no error or inconvenience is caused to the employee 
             during hard times and all claims are processed with ease and efficiency.</p>
          </Col>
        </Row>
        </Container>
    </div>
    )
  }
}

import React, { Component } from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configData from "../config.json";
import "./PoliciesFormFillingPage.css";

export default class GoPolicyKycPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      proposalNum: sessionStorage.getItem("proposalNum"),
      cqrId: sessionStorage.getItem("cqrId"),
      proposalNumber: "",
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();

    this.setState({
      [name]: name === "idNum" ? uppercaseValue : value,
      errors: {
        ...this.state.errors,
        [name]: uppercaseValue,
      },
    });
  };
  handleSubmitClick = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("proposalId", this.state.proposalId);
    formData.append("proposalNum", this.state.proposalNum);
    console.log(formData);
    fetch(configData.api_url + "god_Leads/ckyc/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({
            proposalNum: "",
          });
          window.location.href = "/gopolicythankspage";
        }
      });
  };
  render() {
    return (
      <div>
        <h3 className="logoskybluecolor">PoliciesKYCForm Page</h3>
        <Container >
          <Card>
            <Card.Body>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="idNum" className="form-label">
                    Policy No:
                  </label>
                  <input
                    type="text"
                    name="proposalNum"
                    value={this.state.proposalNum}
                    className="form-control"
                    id="idNum"
                    placeholder="Enter proposal Number"
                    onChange={this.handleChange}
                    disabled
                  />
                </div>
              </div>
              <br />
              <Row className="justify-content-between">
                <Col lg="12" md="12">
                  <Button
                    id="getQuotes4"
                    type="submit"
                    onClick={this.handleSubmitClick}
                    style={{ float: "right" }}
                    disabled={this.state.showAadharForm}
                  >
                    Submit{" "}
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

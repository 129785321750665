import React, { Component } from "react";
import {
  Form,
  Card,
  Col,
  Container,
  Row,
  Table,
  Image,
  Button,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configData from "../config.json";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class GoSharedSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spId: this.props.params.spId,
      proposalId: "",
      baseproductId:"",
      chpiId:"",
      cqrId:"",
      forgotPasswordCondition: false,
      policiescheckbox: "",
      consentCheckbox: false,
      declarationCheckbox: false,
      membersstatus: [],
      products: [],
      members: [],
      address: [],
      details: [],
      medicals: [],
      nominees: [],
      addons: [],
      premium: [],
      email: [],
      kyc:[],
      kycData:[],
      addoncodes: {},
      relations: {},
      nrelations: {},
    };

    // console.log(this.props.params.itId);
  }
  handleInputChange = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  componentDidMount() {
    var formData = new FormData();
    formData.append("spId", this.state.spId);
    fetch(configData.api_url + "shareproposal/retrive/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({proposalId: data.detail.proposalId,baseproductId:data.detail.baseproductId,chpiId:data.detail.chpiId,cqrId:data.detail.cqrId,proposalNum: data.detail.proposalNum,uId: data.detail.createdBy });
          this.apisfetchHandler(data.detail.proposalId);
          this.CaresupremeaddonsfetchHandler(data.detail.chpiId);
          this.addOnsfetchHandler(data.detail.baseproductId);
          this.relationfetchHandler(data.detail.baseproductId);
          this.HealthnomineerelationsfetchHandler(data.detail.baseproductId);
        }
        if (data.status === "error") {
          // alert("Yes");
          window.location = "https://www.apnapolicy.co.in/";
        }
      });
    
    // this.apisfetchPolicyHandler();
  }
  relationfetchHandler = (baseproductId) => {
    var formData = new FormData();
    formData.append("baseproductId", baseproductId);
    fetch(configData.api_url + "C_Healthrelationcodes/listByid/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ relations: data.codes });
        }
      });
  };
  apisfetchPolicyHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("chpiId", this.state.chpiId);
    fetch(configData.api_url + "god_Leads/createpolicy/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ details: data });
        }
      });
  };
  apisfetchHandler = (proposalId) => {
    var formData = new FormData();
    formData.append("proposalId", proposalId);
    fetch(configData.api_url + "god_Leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ kyc: data.lead});
          this.setState({ kycData: data.lead.kyc });
          this.setState({ members: data.lead.members });
          this.setState({ address: data.lead.address });
          this.setState({ medicals: data.lead.medicalqns });
          this.setState({ details: data.lead });
          this.setState({ nominees: data.lead.nominee });
          this.setState({ addons: data.lead.addons });
          this.setState({ premium: data.lead.premium });
          this.setState({ email: data.lead.emaildetails });
        }
      });
  };

  CaresupremeaddonsfetchHandler = (chpiId) => {
    var formData = new FormData();
    formData.append("chpiId", chpiId);
    fetch(configData.api_url + "god_Leads/getproductdetails", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ products: data.product });
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  };
  HealthnomineerelationsfetchHandler = (baseproductId) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", baseproductId);
    fetch(configData.api_url + "C_Healthnomineerelations/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ nrelations: data.codes });
        }
      });
  };
  addOnsfetchHandler = (baseproductId) => {
    var formData = new FormData();
    formData.append("baseproductId", baseproductId);
    fetch(configData.api_url + "C_Chihealthplansintegrated/addonlistByid", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ addoncodes: data.acodes });
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  };
  handleCheckboxChange = (checkboxName) => {
    this.setState((prevState) => ({
      [checkboxName]: !prevState[checkboxName],
    }));
  };
  handleButtonClick = () => {
    const { consentCheckbox, declarationCheckbox } = this.state;
    if (consentCheckbox && declarationCheckbox) {
      // const proposalNum = this.state.proposalNum;
      window.location.href =
      configData.api_url +
      "payments?origin=" +
      configData.web_url +
      "&proposalNum=" +
      this.state.proposalNum +
      "&proposalId=" +
      this.state.proposalId;
      // window.location.href = "/payments/";
    } else {
      toast.error("Please Check Terms And Conditions.");
    }
  };
  handleSubmitEditClick = (e) => {
    window.location.href = "/proposerinfo";
  };
  render() {
    return (
      <div>
        <div>
        
          <Container>
          <h3>Policy Summary</h3>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      {this.state.members.map((quote, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            Plan for {quote.fname} &nbsp;
                            {quote.lname}
                          </td>
                        </tr>
                      ))}
                      <Col lg="3" md="3">
                        <Image
                          style={{
                            width: "100%",
                            marginLeft: "5px",
                            marginTop: "10px",
                          }}
                          src={
                            configData.static_url +
                            this.state.products.insurerlogo
                          }
                          alt="image"
                        ></Image>
                      </Col>
                      <Col lg="6" md="6">
                        <p>
                          <b style={{ color: "red" }}>
                            {this.state.products.productname}
                          </b>
                        </p>

                        <p>
                          <b>Cover Rs -{this.state.products.suminsured}/-</b>
                        </p>
                      </Col>
                      <Col lg="3" md="3">
                        <p>
                          <b>Premium - {this.state.details.tenure}</b>
                        </p>

                        <p>
                          <b>Rs - {this.state.premium.premium}/-</b>
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <br />
                    <h4>Proposer Details</h4>
                    <Row>
                      <Col>
                        <p>
                          We have shared these details on your email &nbsp;
                          <b>{this.state.email.emailId}</b>
                         
                        </p>  
                      </Col>
                    </Row>
                    <Table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "50px" }}>
                            S No.
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            First Name
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            Last Name
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            Date Of Birth
                          </th>
                         <th scope="col" style={{ width: "180px" }}>
                           Mobile Number
                          </th>
                       <th scope="col" style={{ width: "100px" }}>
                           Pincode
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                           1.
                          </td>
                          <td>
                             {this.state.kyc.fname}
                          </td>
                          <td> {this.state.kyc.lname}</td>
                          <td> {this.state.kycData.dob}</td>
                           <td> {this.state.kyc.mobile}</td>
                          <td> {this.state.kyc.zipcode}</td>
                        </tr>
                       
       </tbody>
                    </Table>
                    <hr />
                    <Row className="mt-3">
                      <Col>
                        <h4>Address</h4>
                        <Table className="table table-bordered">
                          <thead>
                              <tr className="text-center">
                          <th scope="col" style={{ width: "50px" }}>
                            S No.
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            Area
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            City
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                          State
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                        Pincode
                          </th>

                         
                        </tr>
                          </thead>
                          <tbody>
                             {this.state.address.map((quote, index) => (
                          <tr key={index} className="text-center">
                             <td>{index + 1}</td>
                            <td>{quote.areaCd}</td>
                            <td>{quote.cityCd}</td>
                            <td>{quote.stateCd}</td>
                            <td>{quote.pincode}</td>
                          </tr>
                        ))}
                          </tbody>
                        </Table>
                       
                      </Col>
                    </Row>
                    <br />
                    <hr />
                    <h4>Insured Details</h4>
                    <br />
                    <Row>
                      <Col>
                        <Table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" style={{ width: "50px" }}>
                                S No.
                              </th>
                              <th scope="col" style={{ width: "100px" }}>
                                First Name
                              </th>
                              <th scope="col" style={{ width: "100px" }}>
                                Last Name
                              </th>
                              <th scope="col" style={{ width: "100px" }}>
                                Date Of Birth
                              </th>
                              <th scope="col" style={{ width: "50px" }}>
                                Age
                              </th>
                              <th scope="col" style={{ width: "70px" }}>
                                Gender (F/M)
                              </th>
                              <th scope="col" style={{ width: "180px" }}>
                                Relation with Proposer
                              </th>
                              <th scope="col" style={{ width: "100px" }}>
                                Height
                              </th>
                              <th scope="col" style={{ width: "100px" }}>
                                Weight
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.members.map((quote, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{quote.fname}</td>
                                <td>{quote.lname}</td>
                                <td>{quote.dob}</td>
                                <td>{quote.age}</td>
                                <td>{quote.gender}</td>
                                <td>{this.state.relations[quote.relation]}</td>
                                <td>{quote.height}</td>
                                <td>{quote.weight}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <br />
                    <hr />
                    <br />
                    <h3>Medicals</h3>
                    <Row>
                      <Table>
                        <tbody>
                          {this.state.members.map((member, memberIndex) => (
                            <tr key={memberIndex}>
                              <td>{member.fname}</td>
                              <td>
                                <table>
                                  <thead>
                                    <tr>
                                      <tr>
                                        <th>P.E.D questions</th>
                                        {/* Add other relevant headers */}
                                      </tr>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.medicals
                                      .filter(
                                        (medical) =>
                                          medical.memberId === member.memberId
                                      )
                                      .filter((medical) => medical.ans !== "")
                                      .filter((medical) => medical.ans !== "No")
                                      .filter(
                                        (medical) =>
                                          medical.lhmId === member.lhmId
                                      )
                                      .map((medical, index) => (
                                        <tr key={index}>
                                          <td>{medical.questionSetCode}</td>
                                          <td>{medical.ans}</td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Row>
                    <br />
                    <h3>Nominee</h3>
                    <br />
                    <Row>
                      <Col>
                        <span>
                          <h3>
                            {this.state.nominees.name}(
                            {
                              this.state.nrelations[
                                this.state.nominees.relation
                              ]
                            }
                            )
                          </h3>
                        </span>
                      </Col>
                    </Row>
                    <br />
                    <hr />
                    <h4>Addons List</h4>
                    <Row>
                      <Col>
                        {this.state.addons.map(
                          (quote, index) =>
                            quote.isChecked === "yes" && (
                              <ul key={index}>
                                <li style={{ listStyle: "none" }}>
                                  {/* {quote.aoId}&nbsp;&nbsp;&nbsp; */}
                                </li>
                                <li style={{ listStyle: "none" }}>
                                  {this.state.addoncodes[quote.aoCode]}
                                </li>
                              </ul>
                            )
                        )}
                      </Col>
                    </Row>
                    <hr />
                    <br />
                    <Row>
                      <Col md="12" lg="12">
                        <Form.Group className="form-group">
                            <Form.Check
                            type="checkbox"
                            name="consentCheckbox"
                            className="control-label ms-3"
                            id="consentCheckbox"
                            checked={this.state.consentCheckbox}
                            onChange={() =>
                              this.handleCheckboxChange("consentCheckbox")
                            }
                            aria-describedby="consentCheckbox"
                            placeholder="Address Line2"
                          />
                          <Form.Label className="control-label ms-3" >
                          <p style={{"textAlign":"justify"}}>  Consent to receive information from Central KYC
                            Registry through SMS/email on the registered
                            number/email address. I/we agree that the PAN
                            details and other information provided by me/us in
                            the application form may be used by Insurer(s) to
                            download/verify my/our KYC documents from the
                            CERSAI* CKYC portal for processing this application.
                            I/We understand that only the acceptable Officially
                            Valid documents would be relied upon for processing
                            this application. *Central Registry of
                            Securitisation and Asset Reconstruction and Security
                            Interest of India.</p>
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      </Row>
                      <Row>
                      <Col md="12" lg="12">
                        <Form.Group className="form-group">
                     
                          <Form.Check
                          className="control-label ms-3"
                            type="checkbox"
                            name="declarationCheckbox"
                            id="declarationCheckbox"
                            checked={this.state.declarationCheckbox}
                            onChange={() =>
                              this.handleCheckboxChange("declarationCheckbox")
                            }
                            aria-describedby="declarationCheckbox"
                          />
                        
                          <Form.Label className="control-label ms-3">
                            <p style={{"textAlign":"justify"}}>I hereby declare that all information provided above
                            is true, and I accept all Terms & conditions </p>
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />
                    <Row className="mt-5">
                      <Col>
                      &nbsp;
                      </Col>
                      <Col>
                        <Button
                          style={{ float: "right" }}
                          type="button"
                          onClick={this.handleButtonClick}
                        >
                          Pay {this.state.premium.premium}/-
                        </Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}
export default withParams(GoSharedSummary);
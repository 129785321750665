// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.thank-you {
    text-align: center;
  }
  
  .thank-you-header {
    color: #333;
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .bi-hand-thumbs-up-fill {
    margin-bottom: 20px;
  }
  
  .thank-you-text {
    color: #555;
    font-size: 16px;
    text-align: center;
    margin-bottom: 8px;
  }
  
  #justified-paragraph {
    text-align: justify;
  }
  
  /* Style the Download button */
  .btn-success {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
    text-align: center;
    font: normal normal normal 14px/1 FontAwesome;
  }
  
  .btn-success:hover {
    background-color: #218838;
  }
  `, "",{"version":3,"sources":["webpack://./src/SidebarComponent/PlanB/PoliciesThankspage.css"],"names":[],"mappings":";;;AAGA;IACI,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA,8BAA8B;EAC9B;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,6CAA6C;EAC/C;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":["\r\n\r\n\r\n.thank-you {\r\n    text-align: center;\r\n  }\r\n  \r\n  .thank-you-header {\r\n    color: #333;\r\n    font-size: 28px;\r\n    margin-bottom: 10px;\r\n  }\r\n  \r\n  .bi-hand-thumbs-up-fill {\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .thank-you-text {\r\n    color: #555;\r\n    font-size: 16px;\r\n    text-align: center;\r\n    margin-bottom: 8px;\r\n  }\r\n  \r\n  #justified-paragraph {\r\n    text-align: justify;\r\n  }\r\n  \r\n  /* Style the Download button */\r\n  .btn-success {\r\n    background-color: #28a745;\r\n    color: #fff;\r\n    border: none;\r\n    padding: 10px 20px;\r\n    font-size: 16px;\r\n    cursor: pointer;\r\n    border-radius: 5px;\r\n    margin-top: 20px;\r\n    text-align: center;\r\n    font: normal normal normal 14px/1 FontAwesome;\r\n  }\r\n  \r\n  .btn-success:hover {\r\n    background-color: #218838;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { Component } from 'react'
import { Container, Image, Offcanvas } from 'react-bootstrap'
import { BrowserRouter, Link, Routes, Route } from 'react-router-dom'
import Apnapolicy from '../Images/Apnapolicy.png'
import './headerOne.css'
import './header.css'
import ContactUs from '../SidebarComponent/ContactUs'
import FAQs from '../SidebarComponent/FAQs'
import KnowledgeCenter from '../SidebarComponent/KnowledgeCenter'
import Support from '../SidebarComponent/Support';
import NoPolicyFound from '../SidebarComponent/NoPolicyFound'
// import certificate from '../Images/certificate.png'
import MainPage from '../SidebarComponent/MainPage'
// import Loginpage from '../SidebarComponent/Loginpage'
import AboutUs from '../SidebarComponent/AboutUs.js'
import Careers from '../SidebarComponent/Careers'
import Products from '../SidebarComponent/Products'
import Services from '../SidebarComponent/Services'
import Leadership from '../SidebarComponent/Leadership'
import Apnapolicypage from '../SidebarComponent/Apnapolicypage'
// import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import apnapolicycertificate from '../Images/apnapolicycertificate.jpg'
// import Policies from '../SidebarComponent/Policies';
import Quotes from '../SidebarComponent/Quotes';
// import Compare from '../SidebarComponent/Compare'
// import Details from '../SidebarComponent/Details'
import configData from "../config.json";
// import Buy from '../SidebarComponent/Buy'
import PoliciesPage from '../SidebarComponent/PlanB/PoliciesPage.js'
import PoliciesKYCForm from '../SidebarComponent/PlanB/PoliciesKYCForm.js'
import NomineeDetails from '../SidebarComponent/PlanB/NomineeDetails.js'
import Medicals from '../SidebarComponent/PlanB/Medicals.js'
import Memberinfo from '../SidebarComponent/PlanB/Memberinfo.js'
import Proposerinfo from '../SidebarComponent/PlanB/Proposerinfo.js'
import Proposalsummary from '../SidebarComponent/PlanB/Proposalsummary.js'
import AddOns from '../SidebarComponent/PlanB/AddOns.js'
import PoliciesThankspage from '../SidebarComponent/PlanB/PoliciesThankspage.js'
import SharedSummary from '../SidebarComponent/PlanB/SharedSummary.js'
// import NotFound from './NotFound'
import GoAddOns from "../SidebarComponent/Godigit/GoAddOns";
import GoMedicals from "../SidebarComponent/Godigit/GoMedicals";
import GoProposerinfo from "../SidebarComponent/Godigit/GoProposerinfo";
import GoNomineeDetails from "../SidebarComponent/Godigit/GoNomineeDetails";
import GoMemberinfo from "../SidebarComponent/Godigit/GoMemberinfo";
import RGIAddOns from "../SidebarComponent/RelianceGeneral/RGIAddOns";
import RGIBuy from "../SidebarComponent/RelianceGeneral/RGIBuy";
import RGIProposerinfo from "../SidebarComponent/RelianceGeneral/RGIProposerinfo";
import RGINomineeDetails from "../SidebarComponent/RelianceGeneral/RGINomineeDetails";
import RGIMemberinfo from "../SidebarComponent/RelianceGeneral/RGIMemberinfo";
import RGIProposalsummary from "../SidebarComponent/RelianceGeneral/RGIProposalsummary";
import RGIPoliciesThankspage from "../SidebarComponent/RelianceGeneral/RGIPoliciesThankspage";
import RGIPoliciesKYC from "../SidebarComponent/RelianceGeneral/RGIPoliciesKYC";
import RGIMedicals from "../SidebarComponent/RelianceGeneral/RGIMedicals";
import GoPolicySummaryPage from "../SidebarComponent/Godigit/GoPolicySummaryPage";
import GoPolicyKycPage from "../SidebarComponent/Godigit/GoPolicyKycPage";
import GoPolicyThanksPage from "../SidebarComponent/Godigit/GoPolicyThanksPage";
import RgSharedSummary from '../SidebarComponent/RelianceGeneral/RgSharedSummary.js'
import GoSharedSummary from '../SidebarComponent/Godigit/GoSharedSummary.js'
import ICICIPoliciesKYCPage from '../SidebarComponent/ICICI/ICICIPoliciesKYCPage.js'
import ICICIProposeiinfo from '../SidebarComponent/ICICI/ICICIProposeiinfo.js'
import ICICIMemberinfo from '../SidebarComponent/ICICI/ICICIMemberinfo.js'
import ICICIMedicals from '../SidebarComponent/ICICI/ICICIMedicals.js'
import ICICINomineeDetails from '../SidebarComponent/ICICI/ICICINomineeDetails.js'
import ICICIAddons from '../SidebarComponent/ICICI/ICICIAddons.js'
import ICICIProposalsummary from '../SidebarComponent/ICICI/ICICIProposalsummary.js'
import ICICIThanksPage from '../SidebarComponent/ICICI/ICICIThanksPage.js'

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      //showFooter: false, // Initially, the footer is not shown
      companies: [],
      isImageClicked: false,
    }
  }
//image event
handleImageClick = () => {
  this.setState((prevState) => ({
    isImageClicked: !prevState.isImageClicked,
  }));
};

  handleSubmit = (e) => {
    e.preventDefault();
    window.location.href = '/';

  }
  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  handleLinkClick = () => {
    setTimeout(() => {
      this.setState({ show: false });
    }, 1000);
  };

  renderPageNotFound() {
    const { match } = this.props;

    if (match && match.isExact === false) {
      return (
        <div>
          <h1>Page Not Found</h1>
          <p>The requested page "{this.props.location.pathname}" does not exist.</p>
        </div>
      );
    }
  }

  componentDidMount() {
    fetch(configData.api_url + "companies/list")
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          companies: data.list,
        //  showFooter: true, // Show the footer immediately after fetching data
        });
      });
  }

  handleLinkClickOne = () => {
    // Replace "_blank" with the name of the new window or tab, if needed
    window.open("https://app.apnapolicy.co.in/", '_blank');
  };



  render() {
    const { isImageClicked } = this.state;
    const isMobile = window.innerWidth < 996;
    const offcanvasWidth = this.state.show && isMobile ? '100%' : '200px';
    const offcanvasHeight = this.state.show && isMobile ? '340px' : '100%';
    return (
      <div className='app'>


        <BrowserRouter>
          <div className='animate' id="wrapper">
            <nav className="navbar header-top fixed-top navbar-expand-lg offcanvas-wrapper navbar-dark">
              <i
                variant='light'
                className='d-flex justify-content-start fa fa-bars apnapolicyMenuButton'
                style={{ "fontSize": "28px", "color": "#404c6f" }}
                onClick={this.handleShow}
              ></i>
              <Offcanvas
                show={this.state.show}
                {...this.props}
                onHide={this.handleClose}
                style={{ width: offcanvasWidth, height: offcanvasHeight }}
                placement={window.innerWidth < 996 ? 'top' : 'start'}
                backdrop="true"

              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>
                    <Link to='/'>
                      <Image
                        src={Apnapolicy}
                        className='navbar-brand '
                        style={{ "width": "135px" }}
                      /> </Link>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body
                  onClick={this.handleLinkClick}
                >
                  <div id='layoutSidenav'>
                    <div id="layoutSidenav_nav">
                      <nav className="sidenav sidenav-light shadow-right">
                        <div className="sidenav-menu">
                          <div className='nav accordion' id="accordionSidenav">
                            <ul className="navbar-nav align-items-center">
                            <li className='nav-item'>
                                  <Link to="/" className="nav-link active collapsed" >
                                    Home</Link>
                              </li>
                              <li className='nav-item'>
                                <Link to="aboutus " className="nav-link active collapsed" >
                                  About Us</Link></li>
                              <li className='nav-item'>
                                <Link to="services" className="nav-link active collapsed" >
                                  Services</Link>
                              </li>
                              {/* <li className='nav-item'>
                                      <Link to="MissionVisionValue"   className="nav-link active  collapsed" >
                                      MissionVision&Value</Link></li> */}
                              {/* this page is added due to raju anna */}
                              {/* <li className='nav-item'> 
                                      <Link to="leadership" className="nav-link active collapsed" >
                                      Leadership</Link></li> */}
                              <li className='nav-item'>
                                <Link to="products" className="nav-link active collapsed" >
                                  Products</Link></li>
                              {/* <li className='nav-item'>
                                      <Link to="careers " className="nav-link active collapsed" >
                                    Careers </Link></li>
                                    <li className='nav-item'>
                                      <Link to="support" className="nav-link active collapsed" >
                                      Support</Link></li>
                                    <li className='nav-item'>
                                      <Link to="knowledgecenter" className="nav-link active collapsed" >
                                      Knowledge Center</Link></li> */}
                              {/* <li className='nav-item'> <Link to="faqs" className="nav-link active collapsed" >
                                FAQs</Link>
                              </li> */}
                            </ul>
                            <ul className='hide-on-large-screens navbar-nav align-items-center'>
                              <li className='nav-item btn-btn-primary '>
                                <Link className="nav-link active collapsed listed"
                                  to="#"
                                  onClick={this.handleLinkClickOne}
                                >Login</Link>
                              </li>
                              <li className='nav-item'>
                                <Link className='nav-link active collapsed '
                                  style={{ "fontWeight": "bold" }}
                                  to="contactus"
                                >Contact Us</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
              <Link to='/'>
                <Image
                  src={Apnapolicy}
                  className='navbar-brand pe-3 ps-4 ps-lg-2'
                /></Link>
              <form>
                <div
                  className="input-group input-group-joined input-group-solid"
                  style={{ borderRadius: "0px" }}
                ></div>
              </form>
              <ul className="navbar-nav align-items-center ms-auto hide-on-small-screen">
                <li className='nav-item'>
                  <Link className='nav-link '
                    // style={{"fontWeight":"bold","fontSize":"large" }}
                    to="#"
                    onClick={this.handleLinkClickOne}
                  >Login</Link>
                </li>
                <li className='nav-item'>
                  <Link className='nav-link '
                    // style={{"fontWeight":"bold","fontSize":"large" }}
                    to="/contactus"
                  >Contact Us</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div id="layoutSidenav_content">
            <main className='nav-fixedOne'>
              <header>
                <div >
                  <Routes>
                    <Route path='/' element={<MainPage />} />
                    <Route path='bookhealthpolicy' element={<Quotes />}></Route>
                    <Route path='/proposalsummary/:spId/' element={<SharedSummary />}></Route>
                    <Route path='/Goproposalsummary/:spId/' element={<GoSharedSummary />}></Route>
                    <Route path='/Rgproposalsummary/:spId/' element={<RgSharedSummary />}></Route>
                    <Route path='/faqs' element={<FAQs />} />
                    <Route path='/knowledgecenter' element={<KnowledgeCenter />} />
                    <Route path='/support' element={<Support />} />
                    <Route path='/contactus' element={<ContactUs />} />
                    <Route path='/nopolicyfound' element={<NoPolicyFound />}></Route>
                    {/* <Route path='/Loginpage' element={<Loginpage/>}></Route> */}
                    <Route path='/aboutus' element={<AboutUs />}></Route>
                    <Route path='/careers' element={<Careers />} />
                    <Route path='/products' element={<Products />} />
                    <Route path='/services' element={<Services />} />
                    <Route path='/leadership' element={<Leadership />} />
                    <Route path='/apnapolypicage' element={Apnapolicypage}></Route>
                    {/* <Route path="*"  element={<p style={{"textAlign":"center","fontSize":"50px"}}><code>404 NotFound</code></p  >}/> */}
                    <Route path="*" element={<MainPage />} />
                    {/* New changes */}
                    <Route path='/policiespage' element={<PoliciesPage/>}></Route>
                    <Route path='/policiesKYCform' element={<PoliciesKYCForm/>}></Route>
                    <Route
                        path="proposalsummary"
                        element={<Proposalsummary />}
                      ></Route>

                      <Route path="addons" element={<AddOns />}></Route>

                      <Route
                        path="nomineedetails"
                        element={<NomineeDetails />}
                      ></Route>
                       <Route path="medicals" element={<Medicals />}></Route>
                       <Route path="memberinfo" element={<Memberinfo />}></Route>

                      <Route
                        path="proposerinfo"
                        element={<Proposerinfo />}
                      ></Route>
                      <Route path="policiesthankspage" element={<PoliciesThankspage/>}></Route>
    {/* Godigit Routing */}

                      <Route path="goaddons" element={<GoAddOns />}></Route>

                      <Route
                      path="gonomineedetails"
                      element={<GoNomineeDetails />}
                      ></Route>

                      <Route path="gomedicals" element={<GoMedicals />}></Route>

                      <Route
                      path="gomemberinfo"
                      element={<GoMemberinfo />}
                      ></Route>

                      <Route
                      path="goproposerinfo"
                      element={<GoProposerinfo />}
                      ></Route>

                      <Route
                      path="gopolicysummarypage"
                      element={<GoPolicySummaryPage />}
                      ></Route>

                      {/* <Route

                      path="gopolicieskycform"

                      element={<GoPoliciesKYCForm />}

                      ></Route> */}

                      <Route
                      path="gopaymentstatus"
                      element={<GoPolicyKycPage />}
                      ></Route>

                      <Route
                      path="gopolicythankspage"
                      element={<GoPolicyThanksPage />}
                      ></Route>
                      {/*end Godigit Routing */}

                      {/* Reliance Routing */}

                      <Route path="rgiaddOns" element={<RGIAddOns />}></Route>

                      <Route path="rgibuy" element={<RGIBuy />}></Route>

                      <Route
                      path="rgimedicals"
                      element={<RGIMedicals />}
                      ></Route>

                      <Route
                      path="rgimemberinfo"
                      element={<RGIMemberinfo />}
                      ></Route>

                      <Route
                      path="rginomineedetails"
                      element={<RGINomineeDetails />}
                      ></Route>

                      <Route
                      path="rgipolicieskyc"
                      element={<RGIPoliciesKYC />}
                      ></Route>

                      <Route
                      path="rgpaymentstatus"
                      element={<RGIPoliciesThankspage />}
                      ></Route>

                      <Route
                      path="rgiproposalsummary"
                      element={<RGIProposalsummary />}
                      ></Route>

                      <Route
                      path="rgiproposerinfo"
                      element={<RGIProposerinfo />}
                      ></Route>
                       {/* Icici Routing */}
                       <Route
                       path="icicipolicieskycpage"
                       element={<ICICIPoliciesKYCPage/>}
                     ></Route>
                     <Route
                       path="iciciproposeiinfo"
                       element={<ICICIProposeiinfo/>}
                     ></Route>
                     <Route
                       path="icicimemberinfo"
                       element={<ICICIMemberinfo/>}
                     ></Route>
                     <Route
                       path="icicimedicals"
                       element={<ICICIMedicals/>}
                     ></Route>
                     <Route
                       path="icicinomineedetails"
                       element={<ICICINomineeDetails/>}
                     ></Route>
                     <Route
                       path="iciciaddons"
                       element={<ICICIAddons/>}
                     ></Route>
                     <Route
                       path="iciciproposalsummary"
                       element={<ICICIProposalsummary/>}
                     ></Route>
                     <Route
                       path="icicithankspage"
                       element={<ICICIThanksPage/>}
                     ></Route>

                  </Routes>
                </div>
              </header>
             
            </main>
          </div>
          {/* {this.state.showFooter && ( */}
            <section id='footer'>
              <Container>
              <div className='row'> 
             
              </div>
                <div className='row'>
                  <div className='col-xs-12 col-sm-12 col-md-4 mt-2 mt-sm-5 text-center'>
                    <ul className='list-unstyled list-inline social text-left'>
                      <li>
                        <Link to="/"><Image
                          style={{ "width": "200px" }}
                          src={Apnapolicy}
                          alt=''
                        /></Link><br />
                      </li>
                      <li>201/2 2nd Floor,Mandhana Towers,</li>
                      <li> Ameerpet,Hyderabad,</li>
                      <li> Telangana,India - 500016</li>
                      <li>
                      <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;<Link to='/Tel:+918978728484'>+91 8978728484</Link>
                      </li>
                      <li>
                      <i className="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp;<Link to="/mailto:info@apnapolicy.co.in">info@apnapolicy.co.in</Link>
                      </li>
                    </ul>
                  </div>
                  <div className=' col-xs-12 col-sm-12 col-md-2 mt-2 mt-sm-5 text-white text-left'>
                  
                    <ul className=' list-unstyled list-inline social text-left'>
                      <li className=""><Link to='/aboutus' >About Us</Link></li>
                      <li className=""><Link to='/services' >Services</Link></li>
                      <li className=""><Link to='/products' >Products</Link></li>
                      {/* <li className=""><Link to='/faqs' >FAQs</Link></li> */}
                      <li className=""><Link to='/contactus'>Contact Us</Link></li>
                    </ul>
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-2 mt-2 mt-sm-5 text-white text-left'>
                
                    <ul  className=' list-unstyled list-inline social text-left'>
                      <li>
                        
                      <a
                        href={configData.api_url + "uploads/privacy-policy.pdf"}
                        target="_blank"
                        rel="noreferrer"
                      >
                       Privacy Policy
                    </a>
                   
                      </li>
                      <li>
                         
                          <a
                            href={configData.api_url + "uploads/tou.pdf"}
                            target="_blank"
                            rel="noreferrer"
                          >
                           Terms of Use
                         </a>
                         
                      </li>
                      <li>
                      
                      <a
                  href={configData.api_url + "uploads/disclaimer.pdf"}
                  target="_blank"
                  rel="noreferrer"
                >
                  Disclaimer
                </a>{" "}
               
                </li>
                    </ul>
                  </div>
                  <div className='col-xs-12 col-sm-12 col-md-4 mt-2 mt-sm-5 '>
                  {isImageClicked ? (
                    <div className='centered-image-overlay' onClick={this.handleImageClick}>
                      <img
                      src={apnapolicycertificate}
                      alt='Certificate' 
                        className='centered-image'
                      />
                    </div>
                  ) : (
                    <img
                    src={apnapolicycertificate}
                    alt='Certificate' 
                    //style={{"width":"100%"}}
                    style={{ "height":"182px","width":"255px" }}
                      onClick={this.handleImageClick}
                    />
                  )}   
                  </div>
                </div>
              </Container>
            </section>
          {/* )} */}
          <section className='ourCopyright'>
            <Container>
              <div className='row'>
                <div className="col-md-12 text-center">
                  <p className="">Insurance is the subject matter of solicitation. All product information displayed is from the respective Insurance companies. <br/> All Right's Reserved Apnapolicy Insurance Brokers Private Limited &#169; 2024.</p>
                </div>
              </div>
            </Container>
          </section>
        </BrowserRouter>
      </div>
    )
  }
}

import React, { Component } from "react";
import { Button, Col, Row, Card, Form, Container } from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";

import "./Proposerinfo.css";
export default class Proposerinfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      members: [],
      tab1Data: {
        addresstype: "PERMANENT",
        roleCd: "",
        line1: "",
        line2: "",
        areaCd: "",
        cityCd: "",
        stateCd: "",
        pincode: "",
        paddresstype: "COMMUNICATION",
        pline1: "",
        pline2: "",
        pareaCd: "",
        pcityCd: "",
        pstateCd: "",
        ppincode: "",
        mobile: "",
        mobiletype: "MOBILE",
        mobilecode: "+91",
        emailId: "",
        emailtype: "PERSONAL",
        cname: "",
        clname: "",
        dob: "",
      },
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  componentDidMount() {
    this.apisfetchHandler();
  }
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ members: data.lead.members });
          const { tab1Data } = this.state;
          tab1Data.cname = data.lead.fname;
          tab1Data.clname = data.lead.lname;
          tab1Data.mobile = data.lead.mobile;
          tab1Data.emailId = data.lead.emailId;
          tab1Data.pincode = data.lead.zipcode;
          tab1Data.cityCd = data.lead.zipdetails.city;
          tab1Data.stateCd = data.lead.zipdetails.statename;
          tab1Data.areaCd = data.lead.zipdetails.district;
          tab1Data.pareaCd = data.lead.zipdetails.district;
          tab1Data.pcityCd = data.lead.zipdetails.city;
          tab1Data.pstateCd = data.lead.zipdetails.statename;
          tab1Data.ppincode = data.lead.zipcode;
          const addresses = data.lead.address;
          addresses.forEach((address) => {
            if (address.addresstype === "PERMANENT") {
              tab1Data.line1 = address.line1;
              tab1Data.line2 = address.line2;
            } else if (address.addresstype === "COMMUNICATION") {
              tab1Data.pline1 = address.line1;
              tab1Data.pline2 = address.line2;
            }
          });
          tab1Data.fax = data.lead.contactdetails.fax;
          this.setState({ tab1Data: tab1Data });
        }
      });
  };

  handleInputChange = (tab, field, value) => {
    if (tab === "tab1Data" && field === "cname") {
      // Allow only letters in the company name (no numbers)
      const lettersAndSpacesRegex = /^[A-Za-z\s]+$/;

      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    if (tab === "tab1Data" && field === "clname") {
      // Allow only letters in the company name (no numbers)
      const lettersAndSpacesRegex = /^[A-Za-z\s]+$/;

      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    if (tab === "tab1Data" && field === "mobilecode") {
      // Allow only letters in the mobilecode field
      const onlyLettersRegex = /^[A-Za-z]+$/;

      if (value.trim() === "") {
        toast.error("Please enter a non-empty STD/ISD Code.");
        return; // Stop further processing for this field
      } else if (onlyLettersRegex.test(value)) {
        toast.error("Please enter a valid non-text STD/ISD Code.");
        return; // Stop further processing for this field
      }
    }
    if (tab === "tab1Data" && field === "mobile") {
      // Allow only letters in the company name (no numbers)
      const firstDigit = parseInt(value.charAt(0), 10);
      if (firstDigit < 6 || firstDigit > 9) {
        // Display an error toast message or handle the error as needed
        toast.error("Please enter valid mobile number.");
        return; // Stop further processing for this field
      }
    }

    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };
  handleInputPChange1 = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
    if (value.length === 6) {
      var formData = new FormData();
      formData.append("zipcode", value);
      formData.append("baseproductId", this.state.baseproductId);
      fetch(configData.api_url + "leads/getzipdetails/", {
        method: "POST",
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.success);
          if (data.status === "success") {
            const { tab1Data, isSameAsPermanent } = this.state;
            tab1Data.cityCd = data.zipdetails.city;
            tab1Data.stateCd = data.zipdetails.statename;
            tab1Data.areaCd = data.zipdetails.district;
            if (isSameAsPermanent) {
              tab1Data.pareaCd = data.zipdetails.district;
              tab1Data.pcityCd = data.zipdetails.city;
              tab1Data.pstateCd = data.zipdetails.statename;
            }
            this.setState({ tab1Data: tab1Data });
          }
        });
    }
  };
  handleInputPPChange1 = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
    if (value.length === 6) {
      var formData = new FormData();
      formData.append("zipcode", value);
      formData.append("baseproductId", this.state.baseproductId);
      fetch(configData.api_url + "leads/getzipdetails/", {
        method: "POST",
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.success);
          if (data.status === "success") {
            const { tab1Data } = this.state;
            tab1Data.pareaCd = data.zipdetails.district;
            tab1Data.pcityCd = data.zipdetails.city;
            tab1Data.pstateCd = data.zipdetails.statename;
            this.setState({ tab1Data: tab1Data });
          }
        });
    }
  };
  handleSameCheckboxChange = () => {
    const { tab1Data, isSameAsPermanent } = this.state;
    tab1Data.pline1 = tab1Data.line1;
    tab1Data.pline2 = tab1Data.line2;
    tab1Data.pareaCd = tab1Data.areaCd;
    tab1Data.pcityCd = tab1Data.cityCd;
    tab1Data.pstateCd = tab1Data.stateCd;
    tab1Data.ppincode = tab1Data.pincode;
    this.setState({
      isSameAsPermanent: !isSameAsPermanent,
      tab1Data: tab1Data,
    });
  };

  handleInputChange1 = (tab, field, value) => {
    // Existing logic to update the state with the entered value
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));

    // Spot validation for line1
    if (field === "line1" && /[@$%^*~`()_+=?":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line One.");
    }

    // Spot validation for line2
    if (field === "line2" && /[@$%^*()_+=?~`":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line Two.");
    }
    if (value.length >= 60) {
      toast.error("Input should not exceed 60 characters.");
    }
    // Spot validation for line1
    if (field === "pline1" && /[@$%^*~`()_+=?":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line One.");
    }

    // Spot validation for line2
    if (field === "pline2" && /[@$%^*()_+=?~`":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line Two.");
    }
    if (value.length >= 60) {
      toast.error("Input should not exceed 60 characters.");
    }
    // Check if the "isSameAsPermanent" flag is enabled
    if (this.state.isSameAsPermanent) {
      // If enabled, update the corresponding field in the "p" category
      const field1 = "p" + field;
      this.setState((prevState) => ({
        [tab]: {
          ...prevState[tab],
          [field1]: value,
        },
      }));
    }
  };

  validateForm() {
    const { tab1Data } = this.state;
    let formIsValid = true;

    if (!tab1Data["addresstype"]) {
      formIsValid = false;
      toast.error("Please Enter Address Type.");
    } else if (!tab1Data["line1"]) {
      formIsValid = false;
      toast.error("Please Enter Address Line One.");
    } else if (tab1Data["line1"].length > 60) {
      formIsValid = false;
      toast.error("Please Enter Address Should 60 Characters.");
    } else if (/[@$%^*~`()_+=?":{}|<>\\[\];""']/.test(tab1Data["line1"])) {
      formIsValid = false;
      toast.error("Enter a valid Address Line One.");
    } else if (!tab1Data["line2"]) {
      formIsValid = false;
      toast.error("Please Enter Address Line Two.");
    } else if (tab1Data["line2"].length > 60) {
      formIsValid = false;
      toast.error("Please Enter Address Should 60 Characters.");
    } else if (/[@$%^*()_+=?~`":{}|<>\\[\];""']/.test(tab1Data["line2"])) {
      formIsValid = false;
      toast.error("Enter a valid Address Line Two.");
    } else if (!tab1Data["areaCd"]) {
      formIsValid = false;
      toast.error("Enter Area.");
    } else if (tab1Data["areaCd"].length > 30) {
      formIsValid = false;
      toast.error("Please Enter Area Should 30 Characters.");
    } else if (/[@$%^*()_+=?":{}|<>\\[\];""']/.test(tab1Data["areaCd"])) {
      formIsValid = false;
      toast.error("Enter a valid Area.");
    } else if (!tab1Data["cityCd"]) {
      formIsValid = false;
      toast.error("Enter Your City.");
    } else if (tab1Data["cityCd"].length > 60) {
      formIsValid = false;
      toast.error("Please Enter City Should 60 Characters.");
    } else if (/[@$%^*()_+=?":{}|<>\\[\];""']/.test(tab1Data["cityCd"])) {
      formIsValid = false;
      toast.error("Enter a valid City.");
    } else if (!tab1Data["stateCd"]) {
      formIsValid = false;
      toast.error("Enter State Code.");
    } else if (tab1Data["stateCd"].length > 60) {
      formIsValid = false;
      toast.error("Please Enter State Code Should 60 Characters.");
    } else if (/[@$%^*()_+=?":{}|<>\\[\];""']/.test(tab1Data["stateCd"])) {
      formIsValid = false;
      toast.error("Enter a valid State Code.");
    } else if (!tab1Data["pincode"]) {
      formIsValid = false;
      toast.error("Enter Your Pincode.");
    } else if (tab1Data["pincode"].length !== 6) {
      formIsValid = false;
      toast.error("Pincode must be 6 characters long.");
    } else if (!tab1Data["mobile"]) {
      formIsValid = false;
      toast.error("Enter Your Mobile Number .");
    } else if (
      tab1Data["mobile"].length !== 10 &&
      tab1Data["mobiletype"] === "MOBILE"
    ) {
      formIsValid = false;
      toast.error("Mobile Number must be 10 digits long.");
    } else if (
      tab1Data["mobile"].length !== 11 &&
      tab1Data["mobiletype"] === "RESIDENTIAL"
    ) {
      formIsValid = false;
      toast.error("Residential Number must be 11 digits long.");
    } else if (!tab1Data["emailId"]) {
      formIsValid = false;
      toast.error("Enter Your Email Id.");
    } else if (tab1Data["emailId"].length > 55) {
      formIsValid = false;
      toast.error("Enter Less Than 55 Characters Email Id.");
    } else if (!/\S+@\S+\.\S+/.test(tab1Data["emailId"])) {
      formIsValid = false;
      toast.error("Enter a valid EmailId.");
    } else if (!tab1Data["mobilecode"]) {
      formIsValid = false;
      toast.error("*Enter Your Std code.");
    }

    return formIsValid;
  }

  addressApiSubmit = () => {
    const addressesList = [
      {
        addresstype: this.state.tab1Data.paddresstype,
        line1: this.state.tab1Data.line1,
        line2: this.state.tab1Data.line2,
        areaCd: this.state.tab1Data.areaCd,
        cityCd: this.state.tab1Data.cityCd,
        stateCd: this.state.tab1Data.stateCd,
        pincode: this.state.tab1Data.pincode,
      },
      {
        addresstype: this.state.tab1Data.addresstype,
        line1: this.state.tab1Data.pline1,
        line2: this.state.tab1Data.pline2,
        areaCd: this.state.tab1Data.pareaCd,
        cityCd: this.state.tab1Data.pcityCd,
        stateCd: this.state.tab1Data.pstateCd,
        pincode: this.state.tab1Data.ppincode,
      },
      // Add more address objects if needed
    ];
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("baseproductId", this.state.baseproductId);
    fd.append("address", JSON.stringify(addressesList));
    // console.log(this.state.policiestab2);
    fetch(configData.api_url + "leads/updateleadaddress/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      });
  };
  contactApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("baseproductId", this.state.baseproductId);
    fd.append("mobile", this.state.tab1Data.mobile);
    fd.append("mobiletype", this.state.tab1Data.mobiletype);
    fd.append("mobilecode", this.state.tab1Data.mobilecode);
    console.log(this.state.tab1Data.mobiletype);
    fetch(configData.api_url + "leads/updateleadcontact/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          // this.setState({
          //   pStatus: this.state.pStatus + 1,
          // });
        }
      });
  };
  emailApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("baseproductId", this.state.baseproductId);
    fd.append("emailId", this.state.tab1Data.emailId);
    fd.append("emailtype", this.state.tab1Data.emailtype);
    // console.log(this.state.policiestab2);
    fetch(configData.api_url + "leads/updateleademaildetails/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          window.location.href = "/memberinfo/";
        }
      });
  };

  handleSubmitClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.addressApiSubmit();
      this.contactApiSubmit();
      this.emailApiSubmit();
    }
  };

  render() {
    return (
      <Container className="bgalphacolor">
        <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill  "
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-person-vcard-fill"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Member Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-heart-pulse"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Medicals</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-pencil-square"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Nominee Details</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-clipboard-plus"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Addon's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card className="card-default">
              <Card.Body>
                {/* <Form> */}
                <Row>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        First Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="cname"
                        id="cname"
                        value={this.state.tab1Data.cname}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "cname",
                            e.target.value
                          )
                        }
                        className="form-control"
                        aria-describedby="enterName"
                        placeholder="Enter Name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Last Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="clname"
                        id="clname"
                        value={this.state.tab1Data.clname}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "clname",
                            e.target.value
                          )
                        }
                        className="form-control"
                        aria-describedby="enterName"
                        placeholder="Enter Last Name"
                      />
                    </Form.Group>
                  </Col>

                  <hr />
                  <Form.Label className="control-label">
                    <h5>Communication Address</h5>
                  </Form.Label>
                  <hr />
                  <br />
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Address Line1
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="line1"
                        value={this.state.tab1Data.line1}
                        id="line1"
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "line1",
                            e.target.value
                          )
                        }
                        maxLength={60}
                        placeholder="Address Line1"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Address Line2
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="line2"
                        id="line2"
                        value={this.state.tab1Data.line2}
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "line2",
                            e.target.value
                          )
                        }
                        maxLength={60}
                        aria-describedby="line2"
                        placeholder="Address Line2"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Pin Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="pincode"
                        id="pincode"
                        value={this.state.tab1Data.pincode}
                        onChange={(e) =>
                          this.handleInputPChange1(
                            "tab1Data",
                            "pincode",
                            e.target.value
                          )
                        }
                        className="form-control"
                        aria-describedby="pincode"
                        placeholder="Pin Code"
                        maxLength={6}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">Area</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="areaCd"
                        id="areaCd"
                        value={this.state.tab1Data.areaCd}
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "areaCd",
                            e.target.value
                          )
                        }
                        aria-describedby="areaCd"
                        placeholder="Area"
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">City</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="cityCd"
                        id="cityCd"
                        value={this.state.tab1Data.cityCd}
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "cityCd",
                            e.target.value
                          )
                        }
                        aria-describedby="cityCd"
                        placeholder="City"
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">State</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="stateCd"
                        aria-describedby="State"
                        name="stateCd"
                        value={this.state.tab1Data.stateCd}
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "stateCd",
                            e.target.value
                          )
                        }
                        placeholder="State"
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    {" "}
                    <Form.Label className="control-label">
                      <h5>Permanent Address</h5>
                    </Form.Label>
                  </Col>
                  <Col>
                    <Form.Group
                      className="form-group"
                      style={{ marginTop: "7px" }}
                    >
                      <Form.Check
                        style={{ float: "unset" }}
                        type="checkbox"
                        name="consentCheckbox"
                        id="consentCheckbox"
                        label="Same as Communication Address"
                        checked={this.state.isSameAsPermanent}
                        onChange={this.handleSameCheckboxChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Address Line1
                      </Form.Label>
                      <Form.Control
                        className="form-control"
                        id="pline1"
                        name="pline1"
                        value={this.state.tab1Data.pline1}
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "pline1",
                            e.target.value
                          )
                        }
                        onKeyDown={this.handleKeyDown}
                        maxLength={60}
                        type="text"
                        placeholder="Address Line1"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Address Line2
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="pline2"
                        id="pline2"
                        value={this.state.tab1Data.pline2}
                        onChange={(e) =>
                          this.handleInputChange1(
                            "tab1Data",
                            "pline2",
                            e.target.value
                          )
                        }
                        aria-describedby="pline2"
                        placeholder="Address Line2"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Pin Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="ppincode"
                        value={this.state.tab1Data.ppincode}
                        onChange={(e) =>
                          this.handleInputPPChange1(
                            "tab1Data",
                            "ppincode",
                            e.target.value
                          )
                        }
                        id="ppincode"
                        className="form-control"
                        aria-describedby="ppincode"
                        placeholder="Pin Code"
                        maxLength={6}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">Area</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        name="pareaCd"
                        id="pareaCd"
                        value={this.state.tab1Data.pareaCd}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "pareaCd",
                            e.target.value
                          )
                        }
                        aria-describedby="pareaCd"
                        placeholder="Area"
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">City</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="pcityCd"
                        name="pcityCd"
                        value={this.state.tab1Data.pcityCd}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "pcityCd",
                            e.target.value
                          )
                        }
                        aria-describedby="pcityCd"
                        placeholder="City"
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">State</Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        id="pstateCd"
                        name="pstateCd"
                        value={this.state.tab1Data.pstateCd}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "pstateCd",
                            e.target.value
                          )
                        }
                        aria-describedby="pstateCd"
                        placeholder="State"
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Contact/Landline No.
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="mobile"
                        maxLength={10}
                        className="form-control"
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "mobile",
                            e.target.value
                          )
                        }
                        id="mobile"
                        value={this.state.tab1Data.mobile}
                        aria-describedby="mobile"
                        placeholder="Mobile/Landline"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Std/ISD Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="mobilecode"
                        className="form-control"
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "mobilecode",
                            e.target.value
                          )
                        }
                        id="mobilecode"
                        value={this.state.tab1Data.mobilecode}
                        aria-describedby="mobilecode"
                        placeholder="STD/ISD Code"
                        maxLength={4}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="4" lg="4">
                    <Form.Group className="form-group">
                      <Form.Label className="control-label">
                        Email Address
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="emailId"
                        value={this.state.tab1Data.emailId}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "emailId",
                            e.target.value
                          )
                        }
                        id="emailId"
                        className="form-control"
                        aria-describedby="emailId"
                        placeholder="Email"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col>
                    <Button
                      id="getQuotes4"
                      onClick={this.handleSubmitClick}
                      style={{ float: "right" }}
                    >
                      Next
                    </Button>
                  </Col>
                </Row>
                {/* </Form> */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

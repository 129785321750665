import React, { Component } from 'react'
import { Container,Row,Col } from 'react-bootstrap'


export default class Careers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  render() {
    return (
        <div> 
        <Container className='bgalphacolor'>
      <Row>
        <Col lg="12" md="12">
        <h3 className="text-center logoskybluecolor">Careers</h3>
        </Col>
      </Row>
      <Row>
        <p>
        Apna Policy is a specialised, comprehensive
         Insurance Broking and Risk Management firm,
          which caters to all your insurance needs and 
          requirements. We, at Apna Policy, help cover all 
          your risks, build strategies for the future and protect you
           against any unforeseen losses, thereby securing your future and building a brighter and a better tomorrow.
        </p>
         </Row>
        </Container>
    </div>
    )
  }
}

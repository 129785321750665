import React, { Component } from 'react'
import { Link} from 'react-router-dom'
import './MainPage.css'
import configData from "../config.json";
// import Carousel from 'react-multi-carousel';
// import {  Image,  } from 'react-bootstrap'


export default class MainPage extends Component {
  constructor(props){
    super(props);
    this.state={
      insuranceCategories: [], 
      companies: [],     
    }
  };
  componentDidMount() {
    // console.log(configData.api_url);
     fetch(configData.api_url+"insurancetypes/listall")
       .then((response) => response.json())
       .then((data) => { 
         this.setState({ insuranceCategories: data.list });
       })
       fetch(configData.api_url + "companies/list")
       .then((response) => response.json())
       .then((data) => {
         this.setState({
           companies: data.list,
         //  showFooter: true, // Show the footer immediately after fetching data
         });
       });
   }
   


  render() {
    // const responsive = {
    //   superLargeDesktop: {
    //     // the naming can be any, depends on you.
    //     breakpoint: { max: 4000, min: 3000 },
    //     items: 5
    //   },
    //   desktop: {
    //     breakpoint: { max: 3000, min: 1024 },
    //     items: 4
    //   },
    //   tablet: {
    //     breakpoint: { max: 1024, min: 464 },
    //     items: 2
    //   },
    //   mobile: {
    //     breakpoint: { max: 464, min: 0 },
    //     items: 1
    //   }
    // };
    const { insuranceCategories } = this.state;
    return (
      <div>
        {insuranceCategories.map((category, index) => {
        return (
          <div className='container' key={index}>
          <div className='row'><div className='col-md-12 col-lg-12 mainPolicyHeading'><h4>{category.InsuranceCategory}</h4></div></div>
             <div className='row d-flex'>  
                {insuranceCategories[index].insurancetypes.map((type, index) => {
                  return (
                    <div className='col-6 col-sm-4 col-md-3 col-lg-2 policyIconBG' key={index}>
                      <div>
                        <Link to={type.iStatus === "1" ? '/bookhealthpolicy' : '#'}>
                          <span className="material-symbols-outlined"> {type.itText} </span>
                          <h6 className='text-center main_text policyNames'>{type.itName}</h6>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
       {/* <section className='OurParterList mt-4'>
                <div className='container our-partner-section pt-4'>
                  <div className='row mt-3'>
                    <div className='col-md-12'>
                      <h3 className='logonavybluecolor'>Our Partners</h3>
                    </div>
                  </div>
                  <Carousel
                    responsive={responsive}
                    swipeable={false} // Disable swipe gesture
                    draggable={false} // Disable drag and drop
                    showDots={false} // Display navigation dots
                    ssr={true} // Server-side rendering
                    infinite={true} // Infinite loop
                    autoPlay={this.props.deviceType !== 'mobile' ? true : false} // Autoplay
                    autoPlaySpeed={2000} // Autoplay speed (ms)
                    keyBoardControl={true} // Keyboard control
                    customTransition="all .5"
                    transitionDuration={500} // Transition duration (ms)
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={['tablet', 'mobile']}
                    deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                   
                    {this.state.companies.map((company, index) => (

                      <div className='panel panel-default' key={index}>
                        <div className='panel-thumbnail'>
                          <Image className='img-fluid mx-auto d-block' src={configData.static_url + company.cPath} alt={company.cName} ></Image>
                        </div></div>
                    ))}
                  </Carousel>
                </div>
              </section> */}
      </div>
    )
  }
}


import React, { Component } from 'react'
import { Container,Row,Col, Image } from 'react-bootstrap'
import aboutUs from '../Images/aboutUs.jpg'
import './AboutUs.css'

export default class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }
  render() {
    return (
      <div> 
        <Container className='bgalphacolor'>
        <Row><br/></Row>
        <Row>
          <Col lg="5" md="5">
            <Image src={aboutUs} className='img-fluid' alt='Responsive image'/>
          </Col>
          <Col lg="7" md="7">
          <h3 className="logoskybluecolor">About Us</h3>
          <p>Apna Policy is a specialised, comprehensive Insurance Broking and Risk Management firm,
             which caters to all your insurance needs and requirements.  We, at Apna Policy, 
             help cover all your risks, build strategies for the future and protect you against any unforeseen losses,
              thereby securing your future and building a brighter and a better tomorrow.
          </p>
          <p>Our core team consists of individuals with considerable experience of years in the Insurance industry and 
            have the experience and expertise to handle multiple lines of Insurance.  We strongly believe that any
             risk either big or small can totally eliminated or minimised with proper planning, strategy adoption,
              implementation and execution.Forecasting risks, analysing market trends and devising plans 
              to eliminate any losses is OUR FORTE.
            </p>
            <h2>Why Insurance and Why Apna Policy?</h2>
            <p>Future as we know is unpredictable and risk and losses are a part and parcel of any endeavour.
               It is more important today than ever to mitigate the risks and protect oneself against any unforeseen
                circumstances. Insurance helps reduce and eliminate risks and losses to the maximum limit possible. 
                Advice and guidance from the experts are advisable and recommended.
            </p>
            <p>Apna Policy specialises in providing all types of Insurance and Risk coverage services to Individuals 
              as well as Corporates. Our ethical practices, healthy competitive spirit and accurate advice, have earned 
              us as lot of goodwill and Trust with Insurance companies as well as clients. This has  made  us a much sought
               after Insurance Broking firm.
            </p>
          </Col>
        </Row>
        </Container>
    </div>

       
    )
  }
}
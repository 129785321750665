import React, { Component } from 'react';
import './Support.css';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';

export default class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      birthDate: '',
      age: 0,
    };
  }

  componentDidMount() {
    // Calculate the initial age once the component is mounted
    this.calculateAge();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the birth date has changed
    if (prevState.birthDate !== this.state.birthDate) {
      // Recalculate the age if the birth date is updated
      this.calculateAge();
    }
  }

  calculateAge() {
    const birthDate = new Date(this.state.birthDate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();

    // Check if the birth month and day are yet to come this year
    const birthMonth = birthDate.getMonth();
    const birthDay = birthDate.getDate();
    const currentMonth = today.getMonth();
    const currentDay = today.getDate();

    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
      age--;
    }

    // Update the age in the component state
    this.setState({ age });
  }

  handleDateChange = (event) => {
    this.setState({ birthDate: event.target.value });
  };
  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col lg="12" md="12" className='text-center supportMainTopSection'>
                <h3>Raise/ Track ticket</h3>
                <p>You can Raise your concerns here or you can check the status of the raised concerns</p>
            </Col>
          </Row>
          <Row>
            <br/>
          </Row>
          <Row>
              <Col lg="12" md="12" className='text-center supportsecondSection'>
                <p>Raise your concerns by filling the form below with the information OR &nbsp;<button class="btn btn-blue btn-primary">Track your Raised Ticket</button></p>
              </Col>
          </Row>
        </Container>
        <Container>
          <Row className='justify-content-center'>
            <Col lg="12" md="12">
              <Card>
              <Card.Body>
                 <Form>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                       <Row className='d-flex'>
                        <Col lg="4" md="4" className='text-md-right'>
                        <Form.Label for="email_address"  className='col-form-label'>Name *</Form.Label>
                        </Col>
                        <Col lg="6" md="6">
                        <Form.Control  type="text" id="email_address" name="email-address" required="" autofocus="" />
                        </Col>
                       </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                       <Row className='d-flex'>
                        <Col lg="4" md="4" className='text-md-right'>
                        <Form.Label for="email_address"  className='col-form-label'>Mobile Number *</Form.Label>
                        </Col>
                        <Col lg="6" md="6">
                        <Form.Control  type="text" id="email_address" name="email-address" required="" autofocus="" />
                        </Col>
                       </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                       <Row className='d-flex'>
                        <Col lg="4" md="4" className='text-md-right'>
                        <Form.Label for="QueryType"  className='col-form-label'>Query Type *</Form.Label>
                        </Col>
                        <Col lg="6" md="6">
                            <Form.Select aria-label="Default select example" className='form-control' id="exampleFormControlSelect1">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                           </Form.Select>
                        </Col>
                       </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                       <Row className='d-flex'>
                        <Col lg="4" md="4" className='text-md-right'>
                        <Form.Label for="QueryType"  className='col-form-label'>Description *</Form.Label>
                        </Col>
                        <Col lg="6" md="6">
                        <Form.Control  type="text" id="email_address" name="email-address" required="" autofocus="" />
                        </Col>
                       </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                       <Row className='d-flex'>
                        <Col lg="4" md="4" className='text-md-right'>
                        <Form.Label for="email_address"  className='col-form-label'>Name *</Form.Label>
                        </Col>
                        <Col lg="6" md="6">
                        <Form.Control as="textarea" rows={3}  className='form-control'id="exampleFormControlTextarea1" />
                        </Col>
                       </Row>
                    </Form.Group>
                   <Row>
                    <Col lg={{span:6,offset:4}} md={{span:6,offset:4}}>
                    <button type="submit" class="btn btn-primary"> Raise Ticket </button>
                    </Col>
                   </Row>
                 </Form>
              </Card.Body>
            
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
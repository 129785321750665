import React, { Component } from 'react'

export default class Apnapolicypage extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }
  render() {
    return (
      <div>
        <h4 className='text-center'>Apnapolicy page </h4>
      </div>
    )
  }
}

import React, { Component } from 'react';
import { Card, Col, Container, Form, Row, Button, Image } from 'react-bootstrap'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify'
import getQuotation from '../Images/getQuotation.jpg'


import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import configData from "./../../src/config.json";
import PoliciesPage from './PlanB/PoliciesPage';
import './Quotes.css';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Quotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policies: [],
      isEditing: false,
      editIndex: null,
      fname: "",  
      lname: "",
      mobile: "",
      zipcode: "",
      plantype: "1",
      covertype: "",
      suminsured: "",
      Pincode: "",
      tenure: "",
      businesstype: "",
      Health: [],
      relations: [
        { key: "Self", value: "SELF", isDisabled: false },
        { value: "FATH", key: "Father", isDisabled: false },
        { value: "MOTH", key: "Mother", isDisabled: false },
        { value: "SISTER", key: "Sister", isDisabled: false },
        { value: "BROTHER", key: "Brother", isDisabled: false },
        { value: "SPSE", key: "Spouse", isDisabled: false },
        { value: "SONM", key: "Son", isDisabled: false },
        { value: "UDTR", key: "Daughter", isDisabled: false },
        { value: "FLAW", key: "Father-In-Law", isDisabled: false },
        { value: "MLAW", key: "Mother-In-Law", isDisabled: false },
      ],
      rows: [{ id: 1 }],
      elements: [],
      disableGenderRelations: ["MOTH", "MLAW", "FATH", "FLAW", "SELF", "SPSE"],
      errorMessage: "",
      contrast: 100,
      policiesData: {
        relation: "",
        birthDate: null,
        gender: "MALE",
        age: 0,
      },
      error: "",
      setSelf: "",
      puser: null,
      forgotPasswordCondition: false,
    };
    // console.log(this.props.params.itId);
  }
  handleEditClick = (index) => {
    const selectedPolicy = this.state.policies[index];
    this.setState({
      policiesData: { ...selectedPolicy },
      isEditing: true,
      editIndex: index,
    });
  };

  handleSaveClick = () => {
    const { policies, policiesData, editIndex } = this.state;
    policies[editIndex] = { ...policiesData };

    this.setState({
      policies,
      policiesData: {
        relation: "",
        birthDate: "",
        age: "",
        gender: "MALE",
      },
      isEditing: false,
      editIndex: null,
    });
  };
  relationChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    // Set gender based on the selected relation
    let genderValue = "MALE"; // Default to "Male" for most relations

    if (
      fieldValue === "MOTH" ||
      fieldValue === "SISTER" ||
      fieldValue === "UDTR" ||
      fieldValue === "MLAW"
    ) {
      genderValue = "FEMALE"; // Set to "Female" for Mother, Sister, Daughter, Mother in Law
    } else if (
      fieldValue === "FATH" ||
      fieldValue === "BROTHER" ||
      fieldValue === "SON" ||
      fieldValue === "FLAW"
    ) {
      genderValue = "MALE"; // Set to "Male" for Father, Brother, Son, Father in Law
    }

    this.setState((prevState) => ({
      policiesData: {
        ...prevState.policiesData,
        [fieldName]: fieldValue,
        gender: genderValue, // Set the gender based on the relation
      },
      visibletype: fieldValue,
    }));

    // Disable gender selection if the selected relation is one of the specified relations
    const disableGenderRelations = [
      "MOTH",
      "SISTER",
      "DAUGHTER",
      "MLAW",
      "FATH",
      "BROTHER",
      "SON",
      "FLAW",
    ];
    if (disableGenderRelations.includes(fieldValue)) {
      this.setState((prevState) => ({
        disableGender: true,
      }));
    } else {
      this.setState((prevState) => ({
        disableGender: false,
      }));
    }
  };

  handleDateChange = (date) => {
    console.log('Received date:', date); // Log the value of the date parameter

    const ninetyOneDaysAgo = new Date();
    ninetyOneDaysAgo.setDate(ninetyOneDaysAgo.getDate() - 91);

    if (date && date < ninetyOneDaysAgo) { // Ensure date is not null or undefined
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

      this.setState((prevState) => ({
        policiesData: {
          ...prevState.policiesData,
          birthDate: formattedDate,
        },
      }));
    } else {
      toast.error("Age should be at least 91 days", "error");
    }
  };

  ageChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    this.setState((prevState) => ({
      policiesData: {
        ...prevState.policiesData,
        [fieldName]: fieldValue,
      },
    }));
    // console.log(this.state.policiesData)
  };
  genderChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    this.setState((prevState) => ({
      policiesData: {
        ...prevState.policiesData,
        [fieldName]: fieldValue,
      },
      visibletype: fieldValue,
    }));
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fname") {
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;
      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not allowed special characters and Numbers.");

        return;
      }
    }
    if (name === "lname") {
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;
      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not allowed special characters and Numbers.");

        return;
      }
    }
    if (name === "zipcode" && value.length > 0 && value.charAt(0) === "0") {
      // Display an error toast message or handle the error as needed
      toast.error("Zipcode cannot start with the number 0.");
      return; // Stop further processing for this field
    }

    if (name === "mobile" && value.length > 0) {
      const firstDigit = parseInt(value.charAt(0), 10);

      if (firstDigit < 6 || firstDigit > 9) {
        // Display an error toast message or handle the error as needed
        toast.error("Please enter valid mobile number.");
        return; // Stop further processing for this field
      }
    }
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: "", // Clear the corresponding error when the field value changes
      },
    });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleRemoveSpecificRowS = (idx) => {
    console.log(idx);
    const tempRows = this.state.policies; // to avoid  direct state mutation.
    var relDict = tempRows[idx];
    tempRows.splice(idx, 1);
    this.setState({ policies: tempRows });
    console.log(relDict);
    var relations = this.state.relations;
    const disableGenderRelations = this.state.disableGenderRelations;
    relations.forEach(function (item) {
      if (
        item.value === relDict.relation &&
        disableGenderRelations.includes(relDict.relation)
      ) {
        item.isDisabled = false;
      }
    });
    var setSelf = "";
    tempRows.forEach((element) => {
      if (element.relation === "SPSE" || element.relation === "SELF") {
        setSelf = element.gender;
      }
    });
    this.setState({ setSelf: setSelf });
  };
  validateDateOfBirth = () => {
    const currentDate = new Date();
    // const selectedDate = new Date(this.state.policiesData.birthDate);

    // Calculate minimum and maximum allowed dates
    const minDate = new Date();
    minDate.setDate(currentDate.getDate() - 91);

    const maxDate = new Date();
    maxDate.setFullYear(currentDate.getFullYear() - 60);

    return true;
  };
  handleAddClick = (e) => {
    e.preventDefault();
    const { policiesData } = this.state;
    // Check if the required fields are empty
    if (
      !this.state.policiesData.relation ||
      !this.state.policiesData.birthDate ||
      !this.state.policiesData.gender
    ) {
      // Show a warning toast message
      this.showToast("Please fill in all fields before adding a row", "error");
      return; // Do not add the row if fields are not filled
    }
    if (policiesData.relation === "SELF") {
      if (this.state.setSelf !== policiesData.relation) {
        this.setState({
          setSelf: policiesData.gender,
        });
      }
      if (policiesData.gender === this.state.setSelf) {
        this.showToast("Self and spouse genders cannot be same.", "error");
        return;
      }
    }
    if (policiesData.relation === "SPSE") {
      if (this.state.setSelf !== policiesData.relation) {
        this.setState({
          setSelf: policiesData.gender,
        });
      }
      if (policiesData.gender === this.state.setSelf) {
        this.showToast("Self and spouse genders cannot be same.", "error");
        return;
      }
    }

    // Additional validation for the "Father" relation

    // Create a new object for the current row with the same values as policiesData
    const newRow = {
      relation: policiesData.relation,
      birthDate: policiesData.birthDate,
      age: policiesData.age,
      gender: policiesData.gender,
    };

    // Add the new row to the policies array
    this.setState((prevState) => ({
      policies: [...prevState.policies, newRow],
    }));
    var relations = this.state.relations;
    const disableGenderRelations = this.state.disableGenderRelations;
    console.log(relations);
    relations.forEach(function (item) {
      if (
        item.value === policiesData.relation &&
        disableGenderRelations.includes(policiesData.relation)
      ) {
        item.isDisabled = true;
      }
    });
    this.setState({ relations: relations });
    // Clear policiesData for the next row
    this.setState({
      policiesData: {
        relation: "",
        birthDate: "",
        gender: "MALE",
        age: 0,
      },
    });

    let maxage = 0;
    let minage = 0;
    this.state.policies.forEach(function (item) {
      if (minage === 0) minage = item.age;
      if (item.age > maxage) maxage = item.age;
      if (item.age < minage) minage = item.age;
      item.dob = item.birthDate;
    });

    if (maxage >= 97) {
      this.setState((prevState) => ({
        tenure: "1 Year",
      }));
    }

    console.log(this.state.policies);
  };

  handleRemoveClick = (id) => {
    const { rows } = this.state;
    const updatedRows = rows.filter((row) => row.id !== id);
    this.setState({ rows: updatedRows });
  };
  componentDidUpdate(preProps, prevState) {
    if (
      prevState.policiesData.birthDate !== this.state.policiesData.birthDate
    ) {
      this.calculateAge();
    }
  }
  calculateAge() {
    const birthDate = new Date(this.state.policiesData.birthDate);
    const today = new Date();
    // let age = today.getFullYear() - birthDate.getFullYear();
    // const birthMonth = birthDate.getMonth();
    // const birthDay = birthDate.getDate();
    // const currentMonth = today.getMonth();
    // const currentDay = today.getDate();
    // const timeDifference = today.getTime() - birthDate.getTime();
    // const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24)); // Convert milliseconds to days
    // if (
    //   currentMonth < birthMonth ||
    //   (currentMonth === birthMonth && currentDay < birthDay)
    // ) {
    //   age--;
    // }
    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
    let days = today.getDate() - birthDate.getDate();

    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }
    if (years === 24 && months === 11 && days > 29) {
      years++;
    }
    if (years === 60 && months === 11 && days > 29) {
      years++;
    }
    //this.setState({age});
    const fieldName = "age";
    this.setState((prevState) => ({
      policiesData: {
        ...prevState.policiesData,
        [fieldName]: years,
      },
      visibletype: years,
    }));
  }

  onKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      toast.error("Spaces are not allowed.");
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      mobile,
      fname,
      zipcode,
      lname,
      tenure,
      covertype,
      emailId,
      plantype,
      suminsured,
      businesstype,
      policies,
    } = this.state;
    if (!mobile || mobile.trim() === "") {
      this.showToast("Please Enter Your Phone Number", "error");
      return false;
    }
    if (!mobile || mobile.length !== 10) {
      this.showToast("Please Enter Your 10 Digit Phone Number", "error");
      return false;
    }
    if (!fname || fname.trim() === "") {
      this.showToast("Please Enter Your Name", "error");
      return false;
    }
    if (!lname || lname.trim() === "") {
      this.showToast("Please Enter Your Last Name", "error");
      return false;
    }
    if (!zipcode || zipcode.trim() === "") {
      this.showToast("Please Enter Your  Zipcode", "error");
      return false;
    } else if (!zipcode || zipcode.length !== 6) {
      this.showToast("Please Enter 6 Digit Zipcode", "error");
      return false;
    }

    if (!emailId || emailId.trim() === "") {
      this.showToast("Please Enter Your Email Id", "error");
      return false;
    } else if (!emailId || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailId)) {
      this.showToast("Please Enter a Valid Email Id", "error");
      return false;
    } else if (!plantype || plantype.trim() === "") {
      this.showToast("Please Enter Plan Name", "error");
      return false;
    }
    if (!plantype || plantype.trim() === "") {
      this.showToast("Please Enter Plan Name", "error");
      return false;
    }

    if (!covertype || covertype.trim() === "") {
      this.showToast("Please Enter Your Covertype", "error");
      return false;
    }
    if (!suminsured || suminsured.trim() === "") {
      this.showToast("Please Select Sum Insured", "error");
      return false;
    }
    if (!tenure || tenure.length === 0) {
      this.showToast("Please Select Tenure", "error");
      return false;
    }
    if (!businesstype || businesstype.trim() === "") {
      this.showToast("Please Select Business Type", "error");
      return false;
    }
    if (
      this.state.policiesData.relation === "SELF" &&
      this.state.policiesData.gender !== this.state.policiesData.spouseGender
    ) {
      this.showToast("SELF and SPOUSE genders must be equal", "error");
      return;
    }
    if (covertype === "FAMILYFLOATER" && policies.length <= 1) {
      this.showToast("Family Floater requires at least 2 members", "error");
      return false;
    }
    // if (!policies || policies.length === 0) {
    //   this.showToast("Please choose relations", "error");
    //   return false;
    // }

    let maxage = 0;
    let minage = 0;
    let adults = 0;
    let childs = 0;
    let familydefination = "";
    policies.forEach(function (item) {
      if (minage === 0) minage = item.age;
      if (item.age > maxage) maxage = item.age;
      if (item.age < minage) minage = item.age;
      if (item.age >= 25) adults++;
      else childs++;
      item.dob = item.birthDate;
    });
    var tenurer = this.state.tenure;
    if (maxage >= 97) tenurer = "1 Year";
    if (adults >= 1) familydefination = adults + "A";
    if (childs > 0) familydefination = familydefination + "+" + childs + "C";

    var fd = new FormData();
    fd.append("members", JSON.stringify(policies));
    fd.append("fname", this.state.fname);
    fd.append("lname", this.state.lname);
    fd.append("mobile", this.state.mobile);
    fd.append("zipcode", this.state.zipcode);
    fd.append("plantype", this.state.plantype);
    fd.append("covertype", this.state.covertype);
    fd.append("suminsured", this.state.suminsured);
    fd.append("tenure", tenurer);
    fd.append("businesstype", this.state.businesstype);
    fd.append("emailId", this.state.emailId);
    fd.append("maxage", maxage);
    fd.append("familydefination", familydefination);
    fd.append("adults", adults);
    fd.append("childs", childs);
    fd.append("minage", minage);
    console.log(minage);
    fetch(configData.api_url + "leads/add/", {
      method: "POST",
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            policies: [],
            policiesData: {
              relation: "",
              birthDate: "",
              gender: "MALE",
              age: 0,
            },
            fname: "",
            lname: "",
            tenure: "",
            mobile: "",
            zipcode: "",
            plantype: "",
            covertype: "",
            suminsured: "1***3",
            businesstype: "",
            relations: [
              { key: "Self", value: "SELF", isDisabled: false },
              { value: "FATH", key: "Father", isDisabled: false },
              { value: "MOTH", key: "Mother", isDisabled: false },
              { value: "SISTER", key: "Sister", isDisabled: false },
              { value: "BROTHER", key: "Brother", isDisabled: false },
              { value: "SPSE", key: "Spouse", isDisabled: false },
              { value: "SONM", key: "Son", isDisabled: false },
              { value: "UDTR", key: "Daughter", isDisabled: false },
              { value: "FLAW", key: "Father-In-Law", isDisabled: false },
              { value: "MLAW", key: "Mother-In-Law", isDisabled: false },
            ],
            setSelf: "",
          });
          window.location.href = "/policiespage/ ";
          // const proposalId = this.state.data.proposalId;
          // console.log(proposalId);
          // // Store proposalId in sessionStorage
          // sessionStorage.setItem("proposalId", proposalId);
          // Make the second API call using the response data from the first call
          sessionStorage.setItem("proposalId", data.proposalId);

          // Make the second API call using the response data from the first call
        }
      });
  };
  handleButtonFmClick = () => {
    const allQuestionData = [];

    this.state.policiesTable.forEach((medicals) => {
      this.state.members.forEach((member) => {
        const isChecked =
          this.state.showExistingSince[medicals.caresuprememedicalqns_Id]?.[
            member.relation
          ];
        const dateValue =
          this.state.dateValues[medicals.caresuprememedicalqns_Id]?.[
            member.relation
          ] || "";
        const ans = isChecked ? dateValue : false;

        allQuestionData.push({
          csmqId: medicals.caresuprememedicalqns_Id,
          ans: ans,
          lhmId: member.relation,
          productCode: "",
          questionSetCode: "",
          qnDesc: "",
          questionCode: "",
        });
      });
    });

    console.log("All Questions:", allQuestionData);

    // Set allQuestionData in the state
    this.setState({ allQuestionData }, () => {
      // Now call the handleComplete method
      this.handleComplete();
    });
  };
  formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  render() {
    const { forgotPasswordCondition } = this.state;
    if (forgotPasswordCondition) {
      return <PoliciesPage />;
    }
   // const currentDate = new Date().toISOString().split("T")[0];
    const previousYearStart = new Date(); 
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1); // Set the date to the first day of January

    return (
      <div>
        <Container className='bgalphacolor'>
          <Row>
            <Col lg="4" className='getQuotationSideImg d-none d-lg-block'>
              <Image src={getQuotation} className='img-fluid' ></Image>
            </Col>
            <Col lg="8">
            <div id="accordian">
            <Card className="card-default">
                      <Card.Body>
                        <Form>
                          <Row>
                            <Col md="4" lg="4">
                              <Form.Group className="form-group">
                                <Form.Label className="control-label">
                                  Mobile No.
                                </Form.Label>
                                <Form.Control
                                  name="mobile"
                                  onKeyDown={this.onKeyDown}
                                  value={this.state.mobile}
                                  onChange={this.handleChange}
                                  type="text"
                                  placeholder="Enter Mobile No"
                                  id="mobiles"
                                  maxLength={10}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="4" lg="4">
                              <Form.Group className="form-group">
                                <Form.Label className="control-label">
                                  Enter Your First Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="fname"
                                  value={this.state.fname}
                                  onChange={this.handleChange}
                                  placeholder="Enter Your First Name"
                                  id="name"
                                />
                              </Form.Group>
                            </Col>
                            <Col md="4" lg="4">
                              <Form.Group className="form-group">
                                <Form.Label className="control-label">
                                  Enter Your Last Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="lname"
                                  value={this.state.lname}
                                  onChange={this.handleChange}
                                  placeholder="Enter Your Last Name"
                                  id="lname"
                                />
                              </Form.Group>
                            </Col>
                            <Col md="4" lg="4">
                              <Form.Group className="form-group">
                                <Form.Label className="control-label">
                                  Enter The Pin Code
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  onKeyDown={this.onKeyDown}
                                  id="zipcode"
                                  name="zipcode"
                                  value={this.state.zipcode}
                                  onChange={this.handleChange}
                                  placeholder="Enter Zipcode"
                                  maxLength={6}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="4" lg="4">
                              <Form.Group className="form-group">
                                <Form.Label className="control-label">
                                  Email Id
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  id="emailId"
                                  onKeyDown={this.onKeyDown}
                                  name="emailId"
                                  value={this.state.emailId}
                                  onChange={this.handleChange}
                                  placeholder="Enter Email Id"
                                />
                              </Form.Group>
                            </Col>
                            <Col md="4" lg="4">
                              <Form.Group className="form-group">
                                <Form.Label className="control-label">
                                  Plan Type
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="plantype"
                                  value={this.state.plantype}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  id="plantype"
                                >
                                  <option>Select </option>
                                  <option value="Base">Base</option>
                                  <option value="TopUp">TopUp</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md="4" lg="4">
                              <Form.Group className="form-group">
                                <Form.Label className="control-label">
                                  Cover Type
                                </Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="covertype"
                                  value={this.state.covertype}
                                  onChange={this.handleChange}
                                  className="form-control"
                                  id="covertype"
                                >
                                  <option>Select </option>
                                  <option value="INDIVIDUAL">Individual</option>
                                  <option value="FAMILYFLOATER">
                                    Family Floater
                                  </option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md="4" lg="4">
                              <Form.Group className="form-group">
                                <Form.Label className="control-label">
                                  Select Sum Insured
                                </Form.Label>
                                <Form.Select
                                  name="suminsured"
                                  value={this.state.suminsured}
                                  onChange={this.handleChange}
                                  aria-label="Default select example"
                                  className="form-control"
                                  id="suminsured"
                                >
                                  <option>Select </option>
                                  <option value="3">3 Lakhs</option>
                                  <option value="5">5 Lakhs</option>
                                  <option value="7">7 Lakhs</option>
                                  <option value="7.5">7.5 Lakhs</option>
                                  <option value="10">10 Lakhs</option>
                                  <option value="15">15 Lakhs</option>
                                  <option value="20">20 Lakhs</option>
                                  <option value="25">25 Lakhs</option>
                                  <option value="30">30 Lakhs</option>
                                  <option value="50">50 Lakhs</option>
                                  <option value="100">1 Crore</option>
                                  <option value="200">2 Crores</option>
                                  <option value="300">3 Crores</option>
                                  <option value="600">6 Crores</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md="4" lg="4">
                              <Form.Group className="form-group">
                                <Form.Label className="control-label">
                                  Tenure
                                </Form.Label>
                                <Form.Select
                                  name="tenure"
                                  value={this.state.tenure}
                                  onChange={this.handleChange}
                                  aria-label="Default select example"
                                  className="form-control"
                                  id="tenure"
                                >
                                  <option>Select </option>
                                  <option value="1 Year">1 Year</option>
                                  <option value="2 Year">2 Year</option>
                                  <option value="3 Year">3 Year</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                            <Col md="4" lg="4">
                              <Form.Group className="form-group">
                                <Form.Label className="control-label">
                                  Business Type
                                </Form.Label>
                                <Form.Select
                                  name="businesstype"
                                  value={this.state.businesstype}
                                  onChange={this.handleChange}
                                  aria-label="Default Select Example"
                                  className="form-control"
                                  id="businesstype"
                                >
                                  <option>Select </option>
                                  <option value="New">New Business</option>
                                  <option value="RENEWAL">Renewal</option>
                                  <option value="PORTING">Porting</option>
                                </Form.Select>
                              </Form.Group>
                            </Col>
                          </Row>
              <br/>
                          <Row >
                            <Col className="table-responsives">
                              <Form.Label className="control-label">
                                Insured Details
                              </Form.Label>
                              <table 
                                className="table table-bordered table-hover"
                                id="tab_logic"
                              >
                                <thead>
                                  <tr>
                                    <th className="text-center">S.NO</th>
                                    <th className="text-center">Relation</th>
                                    <th
                                      className="text-center"
                                      style={{ width: "100px" }}
                                    >
                                      Date Of Birth
                                    </th>
                                    <th className="text-center">Age</th>
                                    <th className="text-center">Gender</th>
                                    <th className="text-center">Actions</th>
                                  </tr>
                                </thead>
                                {/* <br /><br /><br /><br /><br /><br /><br /><br /><br/> */}

                                <tbody>
                                  {this.state.policies.length > 0 ? (
                                    this.state.policies.map((quote, index) => (
                                      <tr key={index}>
                                        <td className="text-center">
                                          {index + 1}
                                        </td>
                                        <td className="text-center">
                                          {/* {quote.relation} */}
                                          {this.state.relations.find(
                                            (relation) =>
                                              relation.value === quote.relation
                                          )?.key || ""}
                                        </td>
                                        <td className="text-center">
                                          {this.formatDate(quote.birthDate)}
                                        </td>
                                        <td className="text-center">
                                          {quote.age}
                                        </td>
                                        <td className="text-center">
                                          {/* {quote.gender} */}
                                          {quote.gender === "MALE"
                                            ? "Male"
                                            : quote.gender === "FEMALE"
                                              ? "Female"
                                              : "Other"}
                                        </td>
                                        <td className="actionTDBtn text-right">
                                          {/* {this.state.isEditing &&
                                          this.state.editIndex === index ? (
                                            <button
                                              type="button"
                                              className="btn btn-success"
                                              onClick={this.handleSaveClick}
                                            >
                                              Save
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="bi bi-pen btn btn-info"
                                              onClick={() =>
                                                this.handleEditClick(index)
                                              }
                                            ></button>
                                          )} */}
                                          <button
                                            type="button"
                                            variant="danger"
                                            className="bi bi-trash-fill btn btn-danger"
                                            onClick={() =>
                                              this.handleRemoveSpecificRowS(
                                                index
                                              )
                                            }
                                          ></button>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan="6"> No Records</td>
                                    </tr>
                                  )}
                                  <tr>
                                    <td>
                                      <div
                                        className="tableListNumber text-center"
                                        style={{ fontSize: "25px" }}
                                      >
                                        <b>
                                          <i
                                            className="bi bi-list-ol"
                                            aria-hidden="true"
                                          ></i>
                                        </b>
                                      </div>
                                    </td>
                                    <td style={{ width: "250px" }}>
                                      <Form.Group className="form-group">
                                        <Form.Select
                                          name="relation"
                                          value={
                                            this.state.policiesData.relation
                                          }
                                          onChange={this.relationChange}
                                          // value={row.relation || ''}
                                          //onChange={(event) => this.handleChangeRow(row.id, event)}
                                          aria-label="Default select example"
                                          className="form-control"
                                          id="relation"
                                        >
                                          <option>Select</option>
                                          {this.state.relations.map(
                                            (quote, index) => (
                                              <option
                                                key={index}
                                                value={quote.value}
                                                disabled={quote.isDisabled}
                                              >
                                                {quote.key}
                                              </option>
                                            )
                                          )}
                                        </Form.Select>
                                      </Form.Group>
                                    </td>

                                    <td style={{ width: "250px" }}>
                                    <div style={{ width: "250px" }} calendarClassName="custom-calendar">
                                      <Form.Group className="form-group">


                                      <DatePicker
                                            id="birthDate"
                                            name="birthDate"
                                            className="form-control"
                                            // className=" date dob hasDatepicker"
                                            selected={this.state.policiesData.birthDate ? new Date(this.state.policiesData.birthDate) : null}
                                            maxDate={new Date()}
                                            placeholderText="Select Date of Birth"
                                            onChange={this.handleDateChange}
                                        
                                             peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dateFormat="dd/MM/yyyy"
                                            dropdownMode="select" 
                                            popperPlacement="top-start"
                                            minDate={previousYearStart}
                                            popperClassName="date-picker-reports"
                                          /> 
                                          
                                      </Form.Group>
                                      </div>
                                         
                                             
                                    </td>
                                    <td style={{ width: "80px" }}>
                                      <Form.Group className="form-group">
                                        <Form.Control
                                          name="age"
                                          value={
                                            isNaN(this.state.policiesData.age)
                                              ? 0
                                              : this.state.policiesData.age
                                          }
                                          onChange={this.ageChange}
                                          disabled
                                          readOnly
                                          className="age"
                                          type="text"
                                        />
                                      </Form.Group>
                                    </td>
                                    <td style={{ width: "150px" }}>
                                      <Form.Group className="form-group">
                                        <Form.Select
                                          aria-label="Default select example"
                                          className="form-control"
                                          name="gender"
                                          value={this.state.policiesData.gender}
                                          onChange={this.genderChange}
                                          disabled={this.state.disableGender}
                                        >
                                          <option value="MALE">Male</option>
                                          <option value="FEMALE">Female</option>
                                          <option value="OTHER">Other</option>
                                        </Form.Select>
                                      </Form.Group>
                                    </td>
                                    <td className="text-right">
                                      {this.state.isEditing ? (
                                        <Button
                                          type="button"
                                          variant="success"
                                          onClick={this.handleSaveClick}
                                        >
                                          Save
                                        </Button>
                                      ) : (
                                        <Button
                                          type="button"
                                          variant="success"
                                          onClick={this.handleAddClick}
                                          style={{ marginTop: "5px",padding: "10px 20px"}}
                                        >
                                          Add
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                          </Row>
                          <Row className="justify-content-between">
                            <Col lg="12" md="12">
                              {/* <Button
                              style={{ float: 'left',color:"#fff",backgroundColor:"#0069d9","borderColor":"#00062cc"}}
                              onClick={this.handleAddClick}  
                            >
                              Add
                            </Button> */}
                              <Button
                                id="getQuotes4"
                                type="submit"
                                //onButtonClick={this.handleSubmit}
                                onClick={this.handleSubmit}
                                style={{ float: "right" }}
                              >
                                Get Quotes
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Card.Body>
                    </Card>
                  </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
export default withParams(Quotes)
import React, { Component } from 'react'
import { Col, Form, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify'
//import './contactus.css'

export default class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fName:'',
           eMail:'',
           subject:'',
           message:'', 
         
        };
      }
      handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
          [name]: value,
          errors: {
            ...this.state.errors,
            [name]: '', // Clear the corresponding error when the field value changes
          },
        });
      };
      showToast = (msg, type) => {
        var tType = toast.TYPE.INFO;
        if (type === "success") tType = toast.TYPE.SUCCESS;
        if (type === "error") tType = toast.TYPE.ERROR;
        if (type === "warning") tType = toast.TYPE.WARNING;
        toast(msg, { type: tType });
      };
      handleSubmit = (e) => {
        e.preventDefault();
        const {  fName, eMail,subject,message} = this.state;
        console.log(fName)
        if (!fName || fName.trim() === '') {
          this.showToast("Please Enter Your full name", "error");
          return false;
        } 
        if (!eMail || eMail.trim() === '') {
          this.showToast("Please enter Your  Email", "error");
          return false;
        } 
        if (!subject || subject.trim() === '') {
          this.showToast("Please enter Your subject", "error");
          return false;
        } 
        if (!message || message.trim() === '') {
          this.showToast("Please enter Your message ", "error");
          return false;
        } 
    }
  render() {
    return (
        <div className='thm-container'>
        <Row>
          <Col lg="8" md="8">
              <div className='contact-form-content'>
                  <div className='title'>
                      <h3 className='logoskybluecolor'>Contact Us</h3>
                  </div>
                  <Form className='contact-form'>
                      <Form.Control
                       type="text" 
                       name='fName'
                       onChange={this.handleChange}
                      placeholder="Your full name" 
                      />
                      <Form.Control type="text" 
                      name='eMail'
                      placeholder="Your email address"
                      onChange={this.handleChange} />
                      <Form.Control
                       type="text"
                       name='subject' 
                       onChange={this.handleChange}
                        placeholder="Subject" />
                      <Form.Control as="textarea"
                       name='message' 
                       placeholder='message' 
                       onChange={this.handleChange}
                      
                       rows={3} />
                      <button type='submit'
                       className='blue-bg thm-btn'
                       onClick={this.handleSubmit}
                      >Submit Now</button>
                      <div className='form-result'></div>
                  </Form>
              </div>
          </Col>
          <Col lg="4" md="4">
              <div className='contact-info text-center'>
                  <div className='title text-center'>
                      <h3>Details</h3>
                  </div>
                  <div className='single-contact-info'>
                  <h4>Address</h4>
                      <p>201/2 2nd Floor,<br/>
                      Mandhana Towers,<br/> Ameerpet, Hyderabad,<br/> 
                       Telangana, India - 500016</p>
                  </div>
                  <div className='single-contact-info'>
                      <h4>Phone</h4>
                      <p><Link to="/Tel:+918978728484">+91 8978728484</Link></p>
                  </div>
                  <div className='single-contact-info'>
                      <h4>Email</h4>
                      <p><Link to="/mailto:info@apnapolicy.co.i">info@apnapolicy.co.in</Link></p>
                  </div>
                  <div className='single-contact-info'>
                      <h4>Follow</h4>
                     <div className='social'>
                      <Link to="/" className='fa fa-twitter'></Link>
                      <Link to="/" className='fa fa-facebook-f'></Link>
                      <Link to="/" className='fa fa-instagram'></Link>
                      <Link to="/" className='fa fa-google-plus'></Link>
                      <Link to="/" className='fa fa-linkedin'></Link>
                     </div>
                  </div>
              </div>
          </Col>
        </Row>
       
    </div>
    )
  }
}
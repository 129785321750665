import React, { Component } from 'react';
import { Col, Row,Modal, Form, Container,Button,  } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default class NoPolicyFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
          showModal: false
        };
      }
      //modal
      handleShowModal = () => {
        this.setState({ showModal: true });
      };
    
      handleCloseModal = () => {
        this.setState({ showModal: false });
      };
  render() {
    return (
      <div>
        <div className='thm-container'>
            <Row className='comparePolicyScreenTopHeading'>
                <Col lg="2" md="2" sm="2" className='text-center'>
                <button type="button" className="btn btn-secondary" id="comparePolocies" onClick={this.handleShowModal}>Compare</button>
                <div>
                <Modal
                    show={this.state.showModal}
                    onHide={this.handleCloseModal}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>apnapolicy.ihms360.com says</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    please choose more than one policy.
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="primary"
                        onClick={this.handleCloseModal}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                </Col>
                <Col lg="4" md="4" sm="4">
                    Self(24)
                    <Link path="/"><span className="bi bi-pencil-square" aria-hidden="true"></span></Link>
                </Col>
                <Col lg="3" md="3" sm="3">
                    <Form>
                        <Row>
                            <Col lg="4" md="4" sm="4">
                            <Form.Group className='form-group'>
                              <Form.Label  className='control-label col-form-label'>Cover:</Form.Label>
                            </Form.Group>
                            </Col>
                            <Col lg="8" md="8" sm="8">
                            <Form.Group className='form-group'>
                            <Form.Select aria-label="Default select example" className='form-control' id='sumInsured'>
                                <option selected>Choose Cover</option>
                                <option value="1***3">1 lakh to 3 lakh</option>
                                <option value="3***6" selected="selected">3 lakh to 6 lakh</option>
                                <option value="6***9">6 lakh to 9 lakh</option>
                                <option value="9***12">9 lakh to 12 lakh</option>
                              </Form.Select>
                            </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col lg="3" md="3" sm="3">
                <Form>
                        <Row>
                            <Col lg="5" md="5" sm="5">
                            <Form.Group className='form-group'>
                              <Form.Label  className='control-label col-form-label'>Plan Type:</Form.Label>
                            </Form.Group>
                            </Col>
                            <Col lg="7" md="7" sm="7">
                            <Form.Group className='form-group'>
                            <Form.Select aria-label="Default select example" className='form-control' id='planType'>
                                <option selected="selected" value="1">Base</option>
                                <option value="2" selected="selected">Topup</option>
                              </Form.Select>
                            </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
        <Container>
            <Row>
                <br/>
                <input type="hidden" id="itId" value="1"></input>
                <input type="hidden" id="custId" value="189"></input>
            </Row>
        </Container>
        <div className='thm-container'>
            <Row className='compareDetailssection'>
                <Col lg="12" md="12" sm="12" className='compareDetailssectionleft'>
                 <h5> No Policies found.</h5>
                </Col>
            </Row>
          
        </div>
      </div>
    )
  }
}

import React, { Component } from "react";
import "./ICICIProposeiinfo.css";
import { Button, Col, Row, Card, Form, Container } from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

export default class ICICINomineeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordCondition: false,
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      nominess: [],
      relations: [],
      tab1Data: {
        name: "",
        dob: "",
        relation: "",
        mobile: "",
        salutation: "",
        clname: "",
        appintname: "",
        adob: "",
        nrelation: "",
        gname: "",
        cmname: "",
      },
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleInputChange = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };
  handleInputMChange = (tab, field, value) => {
    if (tab === "tab1Data" && field === "firstName") {
      // Allow letters and spaces in the name, including an empty value
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;

      if (!lettersAndSpacesRegex.test(value.trim())) {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    if (tab === "tab1Data" && field === "lastName") {
      // Allow letters and spaces in the name, including an empty value
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;

      if (!lettersAndSpacesRegex.test(value.trim())) {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    var error = "no";
    if (value.length < 1 || value.length > 10) {
      error = "yes";
      toast.error("Please enter valid mobile number.");
    }
    // console.log(error);
    if (error !== "yes") {
      this.setState((prevState) => ({
        [tab]: {
          ...prevState[tab],
          [field]: value,
        },
      }));
    }
  };
  validateForm() {
    const { tab1Data } = this.state;
    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(currentDate.getFullYear() - 98);
    let formIsValid = true;
    if (!tab1Data["name"]) {
      formIsValid = false;
      toast.error("Please Enter Nominee Name.");
    } else if (tab1Data["name"].length > 50) {
      formIsValid = false;
      toast.error("Please Enter Name Should 50 Characters.");
    } else if (!tab1Data["dob"]) {
      formIsValid = false;
      toast.error("Please Enter DateofBirth.");
    } else if (!tab1Data["nrelation"]) {
      formIsValid = false;
      toast.error("Please Select Nominee Relation.");
    } else {
      const dob = new Date(tab1Data["dob"]);

      // Check if DOB is more than 99 years ago or if it's tomorrow's date
      if (dob >= currentDate || dob <= maxDate) {
        formIsValid = false;
        toast.error(
          "Invalid Date of Birth. Age must be below 98 years and not tomorrow's date."
        );
      }
    }
    return formIsValid;
  }

  handleSubmitBackClick = (e) => {
    window.location.href = "/icicimedicals";
  };
  handleSubmitNextClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var fd = new FormData();
      const { dob, adob } = this.state.tab1Data;
      const formattedDob = dob ? format(dob, "yyyy-MM-dd") : "";
      const formattedAdob = adob ? format(adob, "yyyy-MM-dd") : "";
      fd.append("proposalId", this.state.proposalId);
      fd.append("cqrId", this.state.cqrId);
      fd.append("name", this.state.tab1Data.name);
      fd.append("dob", formattedDob);
      fd.append("gname", "");
      fd.append("mobile", "");
      fd.append("adob", formattedAdob);
      fd.append("relation", this.state.tab1Data.relation);
      fd.append("nrelation", this.state.tab1Data.nrelation);
      fd.append("appintname", this.state.tab1Data.appintname);
      fetch(configData.api_url + "Il_Leads/updatenominee/", {
        method: "POST",
        headers: {},
        mode: "cors",
        body: fd, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            window.location.href = "/iciciaddons";
          }
        });
    }
  };

  // componentDidMount() {
  //   const minDate = new Date();
  //   minDate.setFullYear(minDate.getFullYear() - 18);
  //   const maxDate = new Date();
  //   maxDate.setFullYear(maxDate.getFullYear() - 98);
  //   this.setState({ minDate: maxDate, maxDate: minDate });
  //   this.HealthnomineerelationsfetchHandler();
  //   this.relationfetchHandler();
  // }
  componentDidMount() {
    const minDate = new Date();
    //minDate.setFullYear(minDate.getFullYear() - 18);
    // Parse the date of birth from the state
    const dob = new Date(this.state.tab1Data.dob);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 98);
    // Check if the date of birth is valid and above 18 years ago
    const isAbove18 = dob <= minDate;

    // Update the state accordingly
    this.setState((prevState) => ({
      minDate: maxDate,
      // maxDate: minDate,
      tab1Data: {
        ...prevState.tab1Data,
        adob: isAbove18 ? null : prevState.tab1Data.adob,
        appintname: isAbove18 ? "" : prevState.tab1Data.appintname,
        gname: isAbove18 ? "" : prevState.tab1Data.gname,
      },
    }));
    // Fetch other data
    this.HealthnomineerelationsfetchHandler();
    this.relationfetchHandler();
    this.apisfetchHandler();
  }
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "Il_Leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          const { tab1Data } = this.state;

          // Validate and handle dob
          let dob = null;
          let adob = null;
          if (
            data.lead.nominee.dob !== "0000-00-00" &&
            !isNaN(Date.parse(data.lead.nominee.dob))
          ) {
            dob = new Date(data.lead.nominee.dob);
          } else if (
            data.lead.nominee.adob !== "0000-00-00" &&
            !isNaN(Date.parse(data.lead.nominee.adob))
          ) {
            adob = new Date(data.lead.nominee.adob);
          }

          // Update state with fetched data
          this.setState({ members: data.lead.members });
          this.setState({
            members: data.lead.members,
            tab1Data: {
              ...tab1Data,
              salutation: data.lead.nominee.salutation,
              name: data.lead.nominee.name,
              cmname: data.lead.nominee.middleName,
              clname: data.lead.nominee.lastName,
              nrelation: data.lead.nominee.nrelation,
              dob: dob,
              appintname: data.lead.nominee.appintname,
              adob: adob,
              mobile: data.lead.nominee.mobile,
              relation: data.lead.nominee.relation,
            },
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching lead details:", error);
      });
  };
  HealthnomineerelationsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthnomineerelations/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ nominess: data.list });
        }
      });
  };
  relationfetchHandler = () => {
    var formData = new FormData();
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthrelationcodes/listByid/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ relations: data.list });
        }
      });
  };
  render() {
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1); // Set the date to the first day of January
    // Example end year
    const endYear = new Date();
    endYear.setFullYear(endYear.getFullYear() + 10);
    const { dob } = this.state.tab1Data;
    const isAbove18 = dob && new Date().getFullYear() - dob.getFullYear() <= 18;

    return (
      <div>
       <Container>
       <h3 className="logoskybluecolor">ICICI Nominee Details</h3>
       <br />
       <Row id="MainRoOne">
         <Col>
           <Row>
             <Col xs={10} md={10} lg={8}>
               <Card id="firstCarOne">
                 <Row id="firstRoTwo">
                   <Col id="firstColTh3">
                     <span
                       className="bi bi-person-fill"
                       id="activeIcon"
                     ></span>
                   </Col>
                 </Row>
                 <Row>
                   <div>
                     <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                   </div>
                 </Row>
               </Card>
             </Col>
             <Col xs={2} md={2} lg={2}>
               <span
                 className="bi bi-arrow-right"
                 id="activeTitileArrow"
               ></span>
             </Col>
           </Row>
         </Col>
         <Col>
           <Row>
             <Col xs={10} md={10} lg={8}>
               <Card id="firstCarOne">
                 <Row id="firstRoTwo">
                   <Col id="firstColTh3">
                     <span
                       className="bi bi-person-vcard-fill"
                       id="activeIcon"
                     ></span>
                   </Col>
                 </Row>
                 <Row>
                   <div>
                     <h6 style={{ color: "#3d5a95" }}>Member Info</h6>
                   </div>
                 </Row>
               </Card>
             </Col>
             <Col xs={2} md={2} lg={2}>
               <span
                 className="bi bi-arrow-right"
                 id="activeTitileArrow"
               ></span>
             </Col>
           </Row>
         </Col>
         <Col>
           <Row>
             <Col xs={10} md={10} lg={8}>
               <Card id="firstCarOne">
                 <Row id="firstRoTwo">
                   <Col id="firstColTh3">
                     <span
                       onClick={this.handleSubmitBackClick}
                       className="bi bi-heart-pulse"
                       id="activeIcon"
                     ></span>
                   </Col>
                 </Row>
                 <Row>
                   <div>
                     <h6
                       style={{ color: "#3d5a95" }}
                       onClick={this.handleSubmitBackClick}
                     >
                       Medicals
                     </h6>
                   </div>
                 </Row>
               </Card>
             </Col>
             <Col xs={2} md={2} lg={2}>
               <span
                 className="bi bi-arrow-right"
                 id="activeTitileArrow"
               ></span>
             </Col>
           </Row>
         </Col>
         <Col>
           <Row>
             <Col xs={10} md={10} lg={8}>
               <Card id="firstCarOne">
                 <Row id="firstRoTwo">
                   <Col id="firstColTh3">
                     <span
                       className="bi bi-pencil-square"
                       id="activeIcon"
                     ></span>
                   </Col>
                 </Row>
                 <Row>
                   <div>
                     <h6 style={{ color: "#3d5a95" }}>Nominee Details</h6>
                   </div>
                 </Row>
               </Card>
             </Col>
             <Col xs={2} md={2} lg={2}>
               <span className="bi bi-arrow-right" id="inActiveArrow"></span>
             </Col>
           </Row>
         </Col>
         <Col>
           <Row>
             <Col xs={10} md={10} lg={8}>
               <Card id="firstCarOne">
                 <Row id="firstRoTwo">
                   <Col id="inActiveTab">
                     <span
                       className="bi bi-clipboard-plus"
                       id="normalIcon"
                     ></span>
                   </Col>
                 </Row>
                 <Row>
                   <div>
                     <h6 style={{ color: "#98968c" }}>Addon's</h6>
                   </div>
                 </Row>
               </Card>
             </Col>
             <Col xs={2} md={2} lg={2}></Col>
           </Row>
         </Col>
       </Row>
       <Card>
         <Card.Body>
           <Row>
             <Col md="3" lg="3">
               <Form.Group className="form-group">
                 <Form.Label className="control-label">
                   Nominee Name
                 </Form.Label>
                 <Form.Control
                   type="text"
                   name="name"
                   id="name"
                   value={this.state.tab1Data.name}
                   onChange={(e) =>
                     this.handleInputChange("tab1Data", "name", e.target.value)
                   }
                   className="form-control"
                   aria-describedby="enterName"
                   placeholder="Enter Nominee Name"
                 />
               </Form.Group>
             </Col>

             <Col md="3" lg="3">
               <Form.Group className="form-group">
                 <Form.Label className="control-label">
                   Nominee Relationship
                 </Form.Label>
                 <Form.Select
                   name="nrelation"
                   value={this.state.tab1Data.nrelation}
                   onChange={(e) =>
                     this.handleInputChange(
                       "tab1Data",
                       "nrelation",
                       e.target.value
                     )
                   }
                   className="form-control"
                   id="nrelation"
                 >
                   <option>Relationship</option>
                   {this.state.nominess.map((quote, index) => (
                     <option key={index} value={quote.nomineecode}>
                       {quote.nomineerelation}
                     </option>
                   ))}
                 </Form.Select>
               </Form.Group>
             </Col>
             <Col md="3" lg="3">
               <Form.Label className="control-label">Nominee DOB</Form.Label>
               <Form.Group className="form-group">
                 <DatePicker
                   name="dob"
                   dateFormat="dd-MM-yyyy"
                   showTimeSelect={false}
                   selected={this.state.tab1Data.dob}
                   onChange={(date) =>
                     this.handleInputChange(
                       "tab1Data",
                       "dob",
                       date // Date object
                     )
                   }
                   className="form-control"
                   id="dob"
                   placeholderText="Date of Birth"
                   peekNextMonth
                   showMonthDropdown
                   showYearDropdown
                   dropdownMode="select"
                   minDate={previousYearStart}
                   //minDate={this.state.minDate} // Set the minimum selectable date
                   //maxDate={this.state.maxDate}
                   maxDate={new Date()}
                 />
               </Form.Group>
             </Col>
           </Row>
           <Row>
             {" "}
             <Col md="3" lg="3">
               <Form.Group className="form-group">
                 <Form.Label className="control-label">
                   Appointee Name
                 </Form.Label>
                 <Form.Control
                   type="text"
                   name="appintname"
                   id="appintname"
                   value={this.state.tab1Data.appintname}
                   onChange={(e) =>
                     this.handleInputChange(
                       "tab1Data",
                       "appintname",
                       e.target.value
                     )
                   }
                   className="form-control"
                   aria-describedby="enterName"
                   placeholder="Enter Appointee Name"
                   disabled={!isAbove18}
                 />
               </Form.Group>
             </Col>
             <Col md="3" lg="3">
               <Form.Label className="control-label">Appointee DOB</Form.Label>
               <Form.Group className="form-group">
                 <DatePicker
                   dateFormat="dd/MM/yyyy"
                   showTimeSelect={false}
                   selected={this.state.tab1Data.adob}
                   onChange={(date) =>
                     this.handleInputChange(
                       "tab1Data",
                       "adob",
                       date // Date object
                     )
                   }
                   className="form-control"
                   id="adob"
                   placeholderText="Date of Birth"
                   peekNextMonth
                   showMonthDropdown
                   showYearDropdown
                   dropdownMode="select"
                   minDate={this.state.minDate} // Set the minimum selectable date
                   maxDate={this.state.maxDate}
                   disabled={!isAbove18}
                 />
               </Form.Group>
             </Col>
             <Col md="3" lg="3">
               <Form.Group className="form-group">
                 <Form.Label className="control-label">
                   Appointee Relation ship
                 </Form.Label>
                 <Form.Select
                   name="relation"
                   value={this.state.tab1Data.relation}
                   onChange={(e) =>
                     this.handleInputChange(
                       "tab1Data",
                       "relation",
                       e.target.value
                     )
                   }
                   className="form-control"
                   id="relation"
                   disabled={!isAbove18}
                 >
                   <option>Relationship</option>
                   {this.state.relations.map((quote, index) => (
                     <option key={index} value={quote.relationcd}>
                       {quote.relation}
                     </option>
                   ))}
                 </Form.Select>
               </Form.Group>
             </Col>
           </Row>
         </Card.Body>
       </Card>
       <br />

       <Row className="mt-5">
         <Col>
           {" "}
           <Button
             id="getQuotes4"
             onClick={this.handleSubmitBackClick}
             type="submit"
             style={{ float: "left" }}
           >
             Back
           </Button>
         </Col>
         <Col>
           {" "}
           <Button
             id="getQuotes4"
             onClick={this.handleSubmitNextClick}
             type="submit"
             style={{ float: "right" }}
           >
             Submit
           </Button>
         </Col>
         {/* </Form> */}
       </Row>
       </Container>
      </div>
    );
  }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pContainer{
  padding-left: 6%;
  padding-right: 5%;
}
#MainRoOne {
  border: 1px solid #f3f2ee;
  margin: 10px;
  margin-top: 10px;
}
#firstRoTwo {
  background-color: rgb(255, 255, 255);
  width: 100px;
  height: 100px;
  margin: auto;
}
#firstColTh3 {
  background-color: rgb(61, 90, 149);
  border-radius: 50%;
  text-align: center;
}
#activeTitileArrow {
  font-size: 50px;
  color: rgb(61, 90, 149);
  margin-top: 20px;
}
#firstCarOne {
  text-align: center;
  border: 0px;
  margin: auto;
  margin-top: 5px;
}
#activeIcon {
  font-size: 50px;
  color: rgb(255, 255, 255);
  margin-top: 25px;
}
#activeIcon1 {
  font-size: 50px;
  color: rgb(255, 255, 255);
  margin-top: 30px;
}
#normalIcon1 {
  font-size: 50px;
  color: rgb(255, 255, 255);
  margin-top: 30px;
}
#normalIcon {
  font-size: 50px;
  color: rgb(255, 255, 255);
  margin-top: 25px;
}
#inActiveTab {
  background-color: rgb(243, 242, 238);
  border-radius: 50%;
  text-align: center;
}
#inActiveArrow {
  font-size: 50px;
  color: rgb(243, 242, 238);
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/SidebarComponent/Godigit/GoProposerinfo.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,oCAAoC;EACpC,YAAY;EACZ,aAAa;EACb,YAAY;AACd;AACA;EACE,kCAAkC;EAClC,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":["#pContainer{\r\n  padding-left: 6%;\r\n  padding-right: 5%;\r\n}\r\n#MainRoOne {\r\n  border: 1px solid #f3f2ee;\r\n  margin: 10px;\r\n  margin-top: 10px;\r\n}\r\n#firstRoTwo {\r\n  background-color: rgb(255, 255, 255);\r\n  width: 100px;\r\n  height: 100px;\r\n  margin: auto;\r\n}\r\n#firstColTh3 {\r\n  background-color: rgb(61, 90, 149);\r\n  border-radius: 50%;\r\n  text-align: center;\r\n}\r\n#activeTitileArrow {\r\n  font-size: 50px;\r\n  color: rgb(61, 90, 149);\r\n  margin-top: 20px;\r\n}\r\n#firstCarOne {\r\n  text-align: center;\r\n  border: 0px;\r\n  margin: auto;\r\n  margin-top: 5px;\r\n}\r\n#activeIcon {\r\n  font-size: 50px;\r\n  color: rgb(255, 255, 255);\r\n  margin-top: 25px;\r\n}\r\n#activeIcon1 {\r\n  font-size: 50px;\r\n  color: rgb(255, 255, 255);\r\n  margin-top: 30px;\r\n}\r\n#normalIcon1 {\r\n  font-size: 50px;\r\n  color: rgb(255, 255, 255);\r\n  margin-top: 30px;\r\n}\r\n#normalIcon {\r\n  font-size: 50px;\r\n  color: rgb(255, 255, 255);\r\n  margin-top: 25px;\r\n}\r\n#inActiveTab {\r\n  background-color: rgb(243, 242, 238);\r\n  border-radius: 50%;\r\n  text-align: center;\r\n}\r\n#inActiveArrow {\r\n  font-size: 50px;\r\n  color: rgb(243, 242, 238);\r\n  margin-top: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

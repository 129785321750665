import React, { Component } from 'react'
import { Container,Row,Col, Image } from 'react-bootstrap'
import ProductsImag1 from '../Images/ProductsImag1.png'


export default class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }
  render() {
    return (
        <div> 
        <Container className='bgalphacolor'>
        <Row><br/></Row>
        <Row>
          <Col lg="5" md="5">
          <Image src={ProductsImag1} className='img-fluid' alt='Responsive image' style={{"width":"100%"}}/>
          </Col>
          <Col lg="7" md="7" >
            <h3 className="logoskybluecolor">Products</h3>
            <p>We offer a large, exhaustive range of products and services to cover all your requirements.  We provide customized solutions that fit your needs perfectly.</p>
            <p><b>Asset Insurance -</b> provides complete protection to your assets, in case of any unanticipated and unforeseen losses.</p>
            <p><b>Liability Policies -</b> A boost in the market brings about unforseen risks and liabilities, which can be taken care of, with this coverage.</p>
            <p><b>Specialised Insurance -</b> You can protect yourself against any risk arising out of a defective product or service.</p>
            <p><b>Employee Benefits -</b> A responsible company always makes adequate provisions to take care of it's employees and their families, with necessary health care benefits.</p>
            <p><b>Life Insurance -</b> is the protection that you can provide your loved ones, in case of an eventuality.</p>
 
          </Col>
        </Row>
        </Container>
    </div>
    )
  }
}

import React, { Component } from "react";
import { Button, Col, Row, Card, Form, Container } from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./GoProposerinfo.css";
export default class GoProposerinfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      maritalstatus: [],
      members: [],
      occupation: [],
      tab1Data: {
        addresstype: "PERMANENT",
        occupation: "",
        maritalstatus: "",
        roleCd: "",
        line1: "",
        areaCd: "",
        cityCd: "",
        stateCd: "",
        pincode: "",
        height:"",
        adob:"",
        weight:"",
        paddresstype: "COMMUNICATION",
        street: "",
        pareaCd: "",
        pcityCd: "",
        pstateCd: "",
        ppincode: "",
        mobile: "",
        mobiletype: "",
        emailId: "",
        emailtype: "",
        mobilecode: "+91",
        cname: "",
        clname: "",
        personType: "",
        dob: "",
        idTYPE: "",
        idATYPE: "",
        idNum: "",
        aadharno: "",
        gender: "",
        name: "",
        consent_purpose: "this is a consent purpose string pass anything",
        jsonDisplay: null,
        errorMessage: "",
        showAadharForm: false,
        noPanCard: false,
        startDate: new Date(),
      },
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  componentDidMount() {
    this.apisfetchHandler();
    this.HealthmaritalstatusfetchHandler();
    this.HealthoccupationsfetchHandler();
  }
  HealthoccupationsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);

    fetch(configData.api_url + "C_Healthoccupations/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ occupation: data.list });
        }
      });
  };
  HealthmaritalstatusfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthmaritalstatus/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ maritalstatus: data.list });
        }
      });
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    fetch(configData.api_url + "god_Leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ members: data.lead.members });
          const { tab1Data } = this.state;
          tab1Data.cname = data.lead.fname;
          tab1Data.clname = data.lead.lname;
          tab1Data.occupation = data.lead.occupation;
          tab1Data.maritalstatus = data.lead.maritalstatus;
          tab1Data.personType = data.lead.personType;
          tab1Data.mobile = data.lead.mobile;
          tab1Data.emailId = data.lead.emailId;
          tab1Data.pincode = data.lead.zipcode;
          tab1Data.cityCd = data.lead.zipdetails.city;
          tab1Data.stateCd = data.lead.zipdetails.statename;
          tab1Data.areaCd = data.lead.zipdetails.district;
          tab1Data.pareaCd = data.lead.zipdetails.district;
          tab1Data.pcityCd = data.lead.zipdetails.city;
          tab1Data.pstateCd = data.lead.zipdetails.state;
          tab1Data.ppincode = data.lead.zipcode;
          this.setState({ tab1Data: tab1Data });
        }
      });
  };

  handleInputChange = (tab, field, value) => {
    if (tab === "tab1Data" && field === "cname") {
      // Allow only letters in the company name (no numbers)
      const onlyLettersRegex = /^[A-Za-z]+$/;

      if (!onlyLettersRegex.test(value) && value !== "") {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    if (tab === "tab1Data" && field === "clname") {
      // Allow only letters in the company name (no numbers)
      const onlyLettersRegex = /^[A-Za-z]+$/;

      if (!onlyLettersRegex.test(value) && value !== "") {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }

    if (tab === "tab1Data" && field === "mobile") {
      // Allow only letters in the company name (no numbers)
      const firstDigit = parseInt(value.charAt(0), 10);
      if (firstDigit < 6 || firstDigit > 9) {
        // Display an error toast message or handle the error as needed
        toast.error("Please enter valid mobile number.");
        return; // Stop further processing for this field
      }
    }

    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };

  handleInputPChange1 = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
    if (value.length === 6) {
      var formData = new FormData();
      formData.append("zipcode", value);
      // https://dev-api.apnapolicy.co.in/god_Leads/
      fetch(configData.api_url + "god_Leads/getzipdetails/", {
        method: "POST",
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.success);
          if (data.status === "success") {
            const { tab1Data, isSameAsPermanent } = this.state;
            tab1Data.cityCd = data.zipdetails.city;
            tab1Data.stateCd = data.zipdetails.statename;
            tab1Data.areaCd = data.zipdetails.district;
            if (isSameAsPermanent) {
              tab1Data.pareaCd = data.zipdetails.district;
              tab1Data.pcityCd = data.zipdetails.city;
              tab1Data.pstateCd = data.zipdetails.statename;
            }
            this.setState({ tab1Data: tab1Data });
          }
        });
    }
  };
  handleInputPPChange1 = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
    if (value.length === 6) {
      var formData = new FormData();
      formData.append("zipcode", value);
      fetch(configData.api_url + "god_Leads/getzipdetails/", {
        method: "POST",
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.success);
          if (data.status === "success") {
            const { tab1Data } = this.state;
            tab1Data.pareaCd = data.zipdetails.district;
            tab1Data.pcityCd = data.zipdetails.city;
            tab1Data.pstateCd = data.zipdetails.statename;
            this.setState({ tab1Data: tab1Data });
          }
        });
    }
  };
  handleSameCheckboxChange = () => {
    const { tab1Data, isSameAsPermanent } = this.state;
    //tab1Data.street = tab1Data.line1;
    tab1Data.pareaCd = tab1Data.areaCd;
    tab1Data.pcityCd = tab1Data.cityCd;
    tab1Data.pstateCd = tab1Data.stateCd;
    tab1Data.ppincode = tab1Data.pincode;
    this.setState({
      isSameAsPermanent: !isSameAsPermanent,
      tab1Data: tab1Data,
    });
  };

  handleInputChange1 = (tab, field, value) => {
    // Existing logic to update the state with the entered value
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));

    // Spot validation for line1
    if (field === "street" && /[@$%^*~`()_+=?":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid  Address.");
    }

    // Spot validation for line2
    if (field === "line2" && /[@$%^*()_+=?~`":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line Two.");
    }
    if (value.length >= 60) {
      toast.error("Input should not exceed 60 characters.");
    }
    // Spot validation for line1
    if (field === "street" && /[@$%^*~`()_+=?":{}|<>\\[\];''']/.test(value)) {
      toast.error("Enter a valid Address Line One.");
    }

    // Spot validation for line2

    // Check if the "isSameAsPermanent" flag is enabled
    if (this.state.isSameAsPermanent) {
      // If enabled, update the corresponding field in the "p" category
      const field1 = "p" + field;
      this.setState((prevState) => ({
        [tab]: {
          ...prevState[tab],
          [field1]: value,
        },
      }));
    }
  };
  handleDChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log(date);
      console.log(formattedDate);
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        dob: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handledatesChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log(date);
      console.log(formattedDate);
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        dob: formattedDate,
        startDate: new Date(),
      });
    }
  };

  validateForm() {
    const { tab1Data } = this.state;
    let policykyc = this.state;
    // const { idTYPE, personType } = tab1Data;

    // const {personType} =this.state;
    let formIsValid = true;
    if (!policykyc["idTYPE"]) {
      formIsValid = false;
      toast.info("*Please Enter Document Type.");
    } else if (!policykyc["idNum"]) {
      formIsValid = false;
      toast.info("*Please Enter PAN Number.");
    } else if (
      policykyc["idTYPE"] === "PAN" &&
      !/^[A-Z]{3}P[A-Z]{1}[0-9]{4}[A-Z]{1}$/.test(policykyc["idNum"])
    ) {
      // Check PAN validation only when idTYPE is "PAN"
      formIsValid = false;
      toast.info("*Invalid PAN Number format.");
    } else if (policykyc["idNum"].length !== 10) {
      // Check if PAN Number has exactly 10 characters
      formIsValid = false;
      toast.info("*PAN Number should be 10 characters long.");
    } else if (!policykyc["dob"]) {
      formIsValid = false;
      toast.info("*Please Enter Date of Birth.");
    }

    // if (!policykyc["aadharno"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Aadhaar Number.");
    // } else if (policykyc["aadharno"].length !== 4) {
    //   // Check if PAN Number has exactly 10 characters
    //   formIsValid = false;
    //   toast.info("Adhar Number should be 4 characters long.");
    // } else if (!policykyc["name"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Name.");
    // } else if (!policykyc["dob"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Date of Birth.");
    // } else if (!policykyc["gender"]) {
    //   formIsValid = false;
    //   toast.info("*Please Select Gender.");
    // }

    if (!tab1Data["addresstype"]) {
      formIsValid = false;
      toast.error("Please Enter Address Type.");
    } else if (tab1Data["line1"].length > 60) {
      formIsValid = false;
      toast.error("Please Enter Address Should 60 Characters.");
    } else if (/[@$%^*~`()_+=?":{}|<>\\[\];""']/.test(tab1Data["line1"])) {
      formIsValid = false;
      toast.error("Enter a valid Address Line One.");
    } else if (/[@$%^*()_+=?~`":{}|<>\\[\];""']/.test(tab1Data["line2"])) {
      formIsValid = false;
      toast.error("Enter a valid Address Line Two.");
    } else if (!tab1Data["cityCd"]) {
      formIsValid = false;
      toast.error("Enter Your City.");
    } else if (tab1Data["cityCd"].length > 60) {
      formIsValid = false;
      toast.error("Please Enter City Should 60 Characters.");
    } else if (!tab1Data["pincode"]) {
      formIsValid = false;
      toast.error("Enter Your Pincode.");
    } else if (tab1Data["pincode"].length !== 6) {
      formIsValid = false;
      toast.error("Pincode must be 6 characters long.");
    } else if (!tab1Data["mobiletype"]) {
      formIsValid = false;
      toast.error("Enter Your Mobile Type .");
    } else if (!tab1Data["mobile"]) {
      formIsValid = false;
      toast.error("Enter Your Mobile Number .");
    } else if (
      tab1Data["mobile"].length !== 10 &&
      tab1Data["mobiletype"] === "MOBILE"
    ) {
      formIsValid = false;
      toast.error("Mobile Number must be 10 digits long.");
    } else if (
      tab1Data["mobile"].length !== 11 &&
      tab1Data["mobiletype"] === "RESIDENTIAL"
    ) {
      formIsValid = false;
      toast.error("Residential Number must be 11 digits long.");
    } else if (!tab1Data["emailId"]) {
      formIsValid = false;
      toast.error("Enter Your Email Id.");
    } else if (tab1Data["emailId"].length > 55) {
      formIsValid = false;
      toast.error("Enter Less Than 55 Characters Email Id.");
    } else if (!/\S+@\S+\.\S+/.test(tab1Data["emailId"])) {
      formIsValid = false;
      toast.error("Enter a valid EmailId.");
    } else if (!tab1Data["emailtype"]) {
      formIsValid = false;
      toast.error("*Enter Your Emailtype.");
    } else if (!tab1Data["personType"]) {
      formIsValid = false;
      toast.error("*Please Select Person Types");
      return false;
    }

    return formIsValid;
  }
  // handleChange = (e) => {
  //   const { name, value } = e.target;
  //   const uppercaseValue = value.toUpperCase();

  //   this.setState({
  //     [name]: name === "idNum" ? uppercaseValue : value,
  //     [name]: name === "name" ? uppercaseValue : value,

  //     errors: {
  //       ...this.state.errors,
  //       [name]: uppercaseValue,
  //     },
  //     [e.target.name]: e.target.value,
  //   });
  // };
  handleChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();

    this.setState({
      [name]: name === "idNum" ? uppercaseValue : value,
      errors: {
        ...this.state.errors,
        [name]: uppercaseValue,
      },
    });
  };
  handleAadharChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();

    this.setState({
      [name]: name === "name" ? uppercaseValue : value,
      errors: {
        ...this.state.errors,
        [name]: uppercaseValue,
      },
    });
  };
  handleIDTypeChange = (event) => {
    this.setState({
      idTYPE: event.target.value,
      showAadharForm: event.target.value === "AADHAR_CARD", // Show Aadhaar form if selected identity type is 'AADHAR_CARD'
    });
  };

  // handleDChange = (date) => {
  //   this.setState({
  //     dob: date,
  //   });
  // };
  onKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      toast.error("Spaces are not allowed.");
    }
  };
  handleNoPANClick = () => {
    // Function to handle click on "I don't have PAN card"
    this.setState({
      noPanCard: true,
      idTYPE: "ADHAR", // Set default ID type to Aadhar
      showAadharForm: true,
    });
  };

  addressApiSubmit = () => {
    const addressesList = [
      {
        addresstype: this.state.tab1Data.paddresstype,
        line1: this.state.tab1Data.street,
        line2: "",
        areaCd: "",
        cityCd: this.state.tab1Data.pcityCd,
        stateCd: this.state.tab1Data.pstateCd,
        pincode: this.state.tab1Data.ppincode,
      },
      // Add more address objects if needed
    ];
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("address", JSON.stringify(addressesList));
    console.log(this.state.policiestab2);
    fetch(configData.api_url + "god_Leads/updateleadaddress/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      });
  };
  contactApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("mobile", this.state.tab1Data.mobile);
    fd.append("mobiletype", this.state.tab1Data.mobiletype);
    fd.append("mobilecode", "+91");
    console.log(this.state.tab1Data.mobiletype);
    fetch(configData.api_url + "god_Leads/updateleadcontact/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      });
  };
  
  emailApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("emailId", this.state.tab1Data.emailId);
    fd.append("emailtype", this.state.tab1Data.emailtype);
  

    console.log(this.state.policiestab2);
    fetch(configData.api_url + "god_Leads/updateleademaildetails/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          //window.location.href = "/gomemberinfo/";
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      });
  };
  kycApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("chpiId", this.state.chpiId);
    fd.append("idNum", this.state.idNum);
    //fd.append("aadharno", this.state.aadharno);
    fd.append("idTYPE", this.state.idTYPE);
    //fd.append("idATYPE", this.state.idATYPE);
    fd.append("gender", this.state.gender);
    fd.append("name", this.state.name);
    fd.append("dob", this.state.dob);
    fd.append("consent_purpose", "");
    console.log(this.state.policiestab2);
    fetch(configData.api_url + "god_Leads/updateCKYCDetails/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
       .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          window.location.href = "/gomemberinfo/";
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      });
  };
    selfApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("chpiId", this.state.chpiId);
     fd.append("gender", this.state.tab1Data.gender);
    fd.append("height", this.state.tab1Data.height);
    fd.append("weight", this.state.tab1Data.weight);
    fd.append("profession", this.state.tab1Data.occupation);
    fd.append("maritalstatus", this.state.tab1Data.maritalstatus);
    fd.append("dob", this.state.dob);

    console.log(this.state.policiestab2);
    fetch(configData.api_url + "god_Leads/updatelead/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
           window.location.href = "/gomemberinfo/";
        }
      });
  };
  handleSubmitClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      this.addressApiSubmit();
      this.contactApiSubmit();
      this.emailApiSubmit();
      this.selfApiSubmit();
      this.kycApiSubmit();
      
    }
  };
  handleUpdatememberChange = (e, index, fieldName) => {
    const { value } = e.target;
    this.setState((prevState) => {
      const updatedMembers = [...prevState.members];
      updatedMembers[index] = { ...updatedMembers[index], [fieldName]: value };
      return { members: updatedMembers };
    });
  };
  render() {
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1); // Set the date to the first day of January
    // Example end year
    const endYear = new Date();
    endYear.setFullYear(endYear.getFullYear() + 10);

    return (
      <div>
     <Container id="pContainer" >
     <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill  "
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-person-vcard-fill"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Member Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-heart-pulse"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Medicals</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-pencil-square"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Nominee Details</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-clipboard-plus"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Addon's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card className="card-default">
              <Card.Body>
                <Form>
                  <Row>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          First Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="cname"
                          id="cname"
                          value={this.state.tab1Data.cname}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "cname",
                              e.target.value
                            )
                          }
                          className="form-control"
                          aria-describedby="enterName"
                          placeholder="Enter Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          Middle Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="mname"
                          id="mname"
                          value={this.state.tab1Data.cname}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "mname",
                              e.target.value
                            )
                          }
                          className="form-control"
                          aria-describedby="enterName"
                          placeholder="Enter Name"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          Last Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="clname"
                          id="clname"
                          value={this.state.tab1Data.clname}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "clname",
                              e.target.value
                            )
                          }
                          className="form-control"
                          aria-describedby="enterName"
                          placeholder="Enter Last Name"
                        />
                      </Form.Group>
                    </Col>
                    <hr />
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          profession
                        </Form.Label>
                        <Form.Select
                          name="occupation"
                          className="form-control"
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "occupation",
                              e.target.value
                            )
                          }
                          id="occupation"
                          value={this.state.tab1Data.occupation}
                          aria-describedby="occupation"
                          aria-label="Default select example"
                        >
                          <option value="">Select</option>
                          {this.state.occupation.map(
                            (occupation, index, idx) => (
                              <option key={index} value={occupation.occupation}>
                                {occupation.occupation}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          Marital Status
                        </Form.Label>
                        <Form.Select
                          name="maritalstatus"
                          className="form-control"
                          value={this.state.tab1Data.maritalstatus}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "maritalstatus",
                              e.target.value
                            )
                          }
                          id="maritalstatus"
                          // value={this.state.tab2Data.maritalstatus}
                          aria-describedby="maritalstatus"
                          aria-label="Default select example"
                        >
                          <option value="">Select</option>
                          {this.state.maritalstatus.map(
                            (maritalstatus, index, idx) => (
                              <option
                                key={index}
                                value={maritalstatus.maritalstatuscode}
                              >
                                {maritalstatus.maritalstatus}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                     <div className="col-md-4">
                            <label htmlFor="gender" className="form-label">
                              Gender:
                            </label>
                            <Form.Select
                              name="gender"
                              value={this.state.gender}
                             onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "gender",
                              e.target.value
                            )
                          }
                              aria-label="Default select example"
                              className="form-control"
                              id="gender"
                            >
                              <option value="">select</option>
                              <option value="MALE">Male</option>
                              <option value="FEMALE">Female</option>
                              <option value="OTHERS">Other</option>
                            </Form.Select>
                          </div>
                           
                    <Col className="col-md-4">
                            <label htmlFor="dob" className="form-label">
                              DOB:
                            </label>
                            <div calendarClassName="custom-calendar">
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                showTimeSelect={false}
                                dropdownMode="select" 

                                selected={this.state.dob}
                                 onChange={(date) => this.handledatesChange(date
                                   
                                   
                                 )}
                                className="form-control"
                                id="dob"
                                placeholderText="Select Date of Birth"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                minDate={previousYearStart} // Set the minimum selectable date
                                maxDate={endYear} // Set the maximum selectable date
                              />
                            </div>
                          </Col>
                   <Col md="4" lg="4">
                     <Form.Group className="form-group">
                        <Form.Label className="control-label">
                         Height
                        </Form.Label>
                          <Form.Control
                              type="number"
                              min="1"
                              max="240"
                           value={this.state.tab1Data.height}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "height",
                              e.target.value
                            )
                          }
                              className="form-control"
                              name="height"
                              required="required"
                              placeholder="Enter Height in C.Mtr"
                            />
                            </Form.Group>
                          </Col>
                           <Col md="4" lg="4">
                             <Form.Group className="form-group">
                        <Form.Label className="control-label">
                        Weight
                        </Form.Label>
                             <Form.Control
                              type="number"
                              min="0"
                              max="240"
                          
                              className="form-control"
                              name="weight"
                              value={this.state.tab1Data.weight}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "weight",
                              e.target.value
                            )
                          }
                              required="required"
                              placeholder="Enter weight in K.gs"
                            />
                            </Form.Group>
                            </Col>
                  </Row>
                  <hr />
                  <Row>
                    <div className="text-left">
                      <b>Identity Details</b>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="idTYPE" className="form-label">
                          Identity Proof:
                        </label>
                        <Form.Select
                          name="idTYPE"
                          value={this.state.idTYPE}
                          onChange={this.handleIDTypeChange}
                          aria-label="Default select example"
                          className="form-control"
                          id="idTYPE"
                        >
                          <option value="">Select Identity Type</option>
                          <option value="PAN_CARD">PAN Card</option>
                          <option value="AADHAR_CARD">Aadhaar</option>
                        </Form.Select>
                      </div>
                      {this.state.showAadharForm && (
                        <>
                          <div className="col-md-4">
                            <label htmlFor="aadharno" className="form-label">
                              Aadhaar Card No:
                            </label>
                            <input
                              type="text"
                              name="aadharno"
                              value={this.state.aadharno}
                              className="form-control"
                              id="aadharno"
                              placeholder="Enter 12 digit Aadhaar Number"
                              onChange={this.handleAadharChange}
                              maxLength={12}
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="dob" className="form-label">
                              DOB:
                            </label>
                            <div calendarClassName="custom-calendar">
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                dropdownMode="select" 

                                showTimeSelect={false}
                                selected={this.state.dob}
                                onChange={(date) => this.handleDChange(date)}
                                className="form-control"
                                id="dob"
                                placeholderText="Select Date of Birth"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                minDate={previousYearStart} // Set the minimum selectable date
                                maxDate={endYear} // Set the maximum selectable date
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="name" className="form-label">
                              NAME:
                            </label>
                            <input
                              type="text"
                              name="name"
                              value={this.state.name}
                              className="form-control"
                              id="name"
                              placeholder="Enter Name"
                              onChange={this.handleAadharChange}
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="gender" className="form-label">
                              Gender:
                            </label>
                            <Form.Select
                              name="gender"
                              value={this.state.gender}
                              onChange={this.handleAadharChange}
                              aria-label="Default select example"
                              className="form-control"
                              id="gender"
                            >
                              <option value="">select</option>
                              <option value="M">Male</option>
                              <option value="F">Female</option>
                              <option value="O">Other</option>
                            </Form.Select>
                          </div>
                           
                        </>
                      )}
                      {!this.state.showAadharForm &&
                        this.state.idTYPE === "PAN_CARD" && (
                          <>
                            <div className="col-md-4">
                              <label htmlFor="idNum" className="form-label">
                                PAN No:
                              </label>
                              <input
                                type="text"
                                name="idNum"
                                value={this.state.idNum}
                                className="form-control"
                                id="idNum"
                                placeholder="Enter PAN Number"
                                onChange={this.handleChange}
                              />
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="dob" className="form-label">
                                DOB:
                              </label>
                              <div calendarClassName="custom-calendar">
                                <DatePicker
                                  dropdownMode="select" 
                                  dateFormat="dd/MM/yyyy"
                                  showTimeSelect={false}
                                  selected={this.state.dob}
                                  onChange={(date) => this.handleDChange(date)}
                                  className="form-control"
                                  id="dob"
                                  placeholderText="Select Date of Birth"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  minDate={previousYearStart} // Set the minimum selectable date
                                  maxDate={endYear} // Set the maximum selectable date
                                  //disabled={this.state.showAadharForm}
                                />
                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </Row>

                  <hr />

                  <Row>
                    <Col>
                      {" "}
                      <Form.Label className="control-label">
                        Communication Address
                      </Form.Label>
                    </Col>
                    <Col></Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          Person Type
                        </Form.Label>
                        <Form.Select
                          name="personType"
                          id="personType"
                          aria-label="Default select example"
                          className="form-control"
                          value={this.state.personType}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "personType",
                              e.target.value
                            )
                          }
                        >
                          <option>Select Person Type</option>
                          <option value="NATURALPERSON">Natural Person</option>
                          <option value="LEGALPERSON">Legal Person</option>
                        </Form.Select>

                        {/* <Form.Control
                        type="text"
                        name="clname"
                        id="clname"
                        value={this.state.tab1Data.clname}
                        onChange={(e) =>
                          this.handleInputChange(
                            "tab1Data",
                            "clname",
                            e.target.value
                          )
                        }
                        className="form-control"
                        aria-describedby="enterName"
                        placeholder="Enter Last Name"
                      /> */}
                      </Form.Group>
                    </Col>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          Street
                        </Form.Label>
                        <Form.Control
                          className="form-control"
                          id="street"
                          name="street"
                          value={this.state.tab1Data.street}
                          onChange={(e) =>
                            this.handleInputChange1(
                              "tab1Data",
                              "street",
                              e.target.value
                            )
                          }
                          onKeyDown={this.handleKeyDown}
                          maxLength={60}
                          type="text"
                          placeholder="Street"
                        />
                      </Form.Group>
                    </Col>

                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          Pin Code
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="ppincode"
                          value={this.state.tab1Data.ppincode}
                          onChange={(e) =>
                            this.handleInputPPChange1(
                              "tab1Data",
                              "ppincode",
                              e.target.value
                            )
                          }
                          id="ppincode"
                          className="form-control"
                          aria-describedby="ppincode"
                          placeholder="Pin Code"
                          maxLength={6}
                        />
                      </Form.Group>
                    </Col>

                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">City</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="pcityCd"
                          name="pcityCd"
                          value={this.state.tab1Data.pcityCd}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "pcityCd",
                              e.target.value
                            )
                          }
                          aria-describedby="pcityCd"
                          placeholder="City"
                          disabled={true}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">State</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="pstateCd"
                          name="pstateCd"
                          value={this.state.tab1Data.pstateCd}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "pstateCd",
                              e.target.value
                            )
                          }
                          aria-describedby="pstateCd"
                          placeholder="State"
                          disabled={true}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      {" "}
                      <Form.Label className="control-label">
                        Communications Details
                      </Form.Label>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          Contact Type
                        </Form.Label>
                        <Form.Select
                          name="mobiletype"
                          className="form-control"
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "mobiletype",
                              e.target.value
                            )
                          }
                          id="mobiletype"
                          value={this.state.tab1Data.mobiletype}
                          aria-describedby="mobiletype"
                          placeholder="mobiletype"
                        >
                          <option value="">Select</option>
                          <option value="MOBILE">Mobile</option>
                          <option value="MOBILE">Telephone</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          Contact
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="mobile"
                          maxLength={10}
                          className="form-control"
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "mobile",
                              e.target.value
                            )
                          }
                          id="mobile"
                          value={this.state.tab1Data.mobile}
                          aria-describedby="mobile"
                          placeholder="Mobile/Landline"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          Communication Type
                        </Form.Label>
                        <Form.Select
                          name="emailtype"
                          className="form-control"
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "emailtype",
                              e.target.value
                            )
                          }
                          id="emailtype"
                          value={this.state.tab1Data.emailtype}
                          aria-describedby="emailtype"
                          aria-label="Default select example"
                        >
                          <option value="">Select</option>
                          <option value="FAX">Fax</option>
                          <option value="EMAIL">Email</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="4" lg="4">
                      <Form.Group className="form-group">
                        <Form.Label className="control-label">
                          Email Address
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="emailId"
                          value={this.state.tab1Data.emailId}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "emailId",
                              e.target.value
                            )
                          }
                          id="emailId"
                          className="form-control"
                          aria-describedby="emailId"
                          placeholder="Email"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-5">
                    <Col>
                      <Button
                        id="getQuotes4"
                        onClick={this.handleSubmitClick}
                        style={{ float: "right" }}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
     </Container>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
//import "./RGIPoliciesThankspage.css";
// import jsPDF from "jspdf";
import { toast } from "react-toastify";
import configData from "../config.json";

export default class ICICIThanksPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      policyStatus: false,
      downloader: false,
      pdfData: "",
      premium: [],
      download: {},
      dateValues: {},
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  componentDidMount() {
    this.apisfetchHandler();
  }
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    fetch(configData.api_url + "Il_Leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ premium: data.lead.premium });
          this.getproposalStatus();
        }
      });
  };
  getproposalStatus = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("chpiId", this.state.chpiId);
    fetch(configData.api_url + "Il_Leads/getproposalStatus/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // this.showToast(data.message, data.success);
        if (data.status === "success") {
          if (data.policy.policyStatus === "2") {
            var formData = new FormData();
            formData.append("proposalId", this.state.proposalId);
            formData.append("CorrelationID", data.correlationId);
            formData.append("policyNum", data.policy.policyNum);
            formData.append("pId", data.policy.pId);
            formData.append("proposalNum", data.policy.proposalNum);
            formData.append("customerID", this.state.premium.customerID);
            fetch(configData.api_url + "Il_Leads/generateproposalpdf/", {
              method: "POST",
              mode: "cors",
              body: formData,
            })
              .then((response) => response.json())
              .then((data) => {
                this.showToast(data.message, data.success);
                if (data.status === "success") {
                  if (data.policy !== "") {
                    this.setState({ downloader: true });
                  }
                }
              });
            //this.setState({ downloader: true });
          }
        }
      });
  };
  handleDownload = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    fetch(configData.api_url + "Il_Leads/getproposalpdf/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          // this.setState({ pdfData: data.policy.policyPdf });
          if (data.policy.policyPdf !== "") {
            this.downloadPdf(data.policy.policyPdf);
          }
        }
      });
  };
  downloadPdf = (policyPdf) => {
    console.log(this.state.pdfData);
    const pdfUrl = configData.api_url + policyPdf;
    console.log(pdfUrl);
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = this.state.premium.policyNumber + ".pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  render() {
    return (
      <div>
       <Container>
       {this.state.premium.policyStatus === "2" ? (
        <Row className="mt-3">
          <Col>
            <Card>
              <div className="thank-you">
                <h1 className="thank-you-header mt-3">Thank You!</h1>
                <span
                  className="bi bi-hand-thumbs-up-fill"
                  style={{ fontSize: "35px", color: "green" }}
                ></span>
                <br />
                <p className="thank-you-text" id="justified-paragraph ">
                  Transaction number : {this.state.premium.transactionRefNum}
                </p>

                {this.state.premium.policyNumber ? (
                  <div>
                    <p
                      className="thank-you-text"
                      id="justified-paragraph"
                      style={{ textAlign: "revert" }}
                    >
                      Policy number : {this.state.premium.policyNumber}
                    </p>
                    <p className="thank-you-text" id="justified-paragraph ">
                      Your Transaction is successful. Policy details will be
                      sent your email.
                    </p>
                    <p className="thank-you-text" id="justified-paragraph ">
                      We appreciate your business and value you as our
                      customer.
                    </p>
                    {this.state.downloader ? (
                      <>
                        {" "}
                        <div className="thank-you-text">
                          <button
                            type="button"
                            onClick={this.handleDownload}
                            className="policiesthanks btn btn-success"
                          >
                            Download
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <div>
                    {" "}
                    <p
                      className="thank-you-text"
                      id="justified-paragraph"
                      style={{ textAlign: "revert" }}
                    >
                      Referral message : You may continue with the proposal
                      and the Policy shall be issued post completion of Tele
                      underwriting process.
                    </p>
                    <p className="thank-you-text" id="justified-paragraph ">
                      Your Transaction is successful. Policy details will be
                      sent your email.
                    </p>
                    <p className="thank-you-text" id="justified-paragraph ">
                      We appreciate your business and value you as our
                      customer.
                    </p>
                  </div>
                )}
              </div>

              <br />
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}
      {this.state.premium.policyStatus === "3" ? (
        <Row className="mt-5">
          <Col>
            <Card>
              <div className="thank-you">
                <h1 className="thank-you-header">Error</h1>
                <span
                  className="bi bi-exclamation-circle-fill"
                  style={{ fontSize: "35px", color: "red" }}
                ></span>
                <br />
                <p className="thank-you-text">
                  {this.state.premium.errorMsg}
                </p>
                <br />
              </div>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}
       </Container>
      </div>
    );
  }
}
